.activity {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.activity__container {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  padding: 0 64px 64px;
}

.activity__sorting {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 48px;
}

.activity__tags {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-item-align: start;
  align-self: flex-start;
  margin-right: auto;
  padding-top: 12px;
}

.leaderboard__title .activity__tags {
  padding-top: 0px;
}

.activity__tags .activity__link {
  padding: 0 16px;
  border-radius: 20px;
  line-height: 40px;
  font-weight: 600;
  color: #808191;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.activity__tags .activity__link:hover {
  color: #11142d;
}

.activity__tags .activity__link.active {
  background: rgba(228, 228, 228, 0.3);
  color: #8173e0;
}

.activity__select {
  position: relative;
  -ms-flex-item-align: start;
  align-self: flex-start;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 230px;
  margin-left: 50px;
}

.activity__select .select {
  padding-left: 43px;
}

.activity__select .activity__icon {
  position: absolute;
  top: 50%;
  left: 20px;
  z-index: 2;
  pointer-events: none;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 0;
}

.activity__select .activity__icon .icon {
  font-size: 16px;
}

.activity__stage {
  margin-bottom: 32px;
}

.activity__table {
  display: table;
  width: 100%;
}

.activity__row {
  display: table-row;
  border-radius: 12px;
  cursor: pointer;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.activity__row_head {
  background: rgba(228, 228, 228, 0.2);
  font-size: 10px;
  letter-spacing: 1px;
  font-weight: 600;
  color: #808191;
  text-transform: uppercase;
}

.activity__row:nth-child(2n + 1) {
  background: rgba(228, 228, 228, 0.2);
}

.activity__row:not(.activity__row_head):hover {
  -webkit-box-shadow: 0 0 0 2px #6c5dd3;
  box-shadow: 0 0 0 2px #6c5dd3;
  background: none;
}

.activity__row:not(.activity__row_head).active {
  -webkit-box-shadow: 0 0 0 2px #6c5dd3;
  box-shadow: 0 0 0 2px #6c5dd3;
  background: none;
}

.activity__row_head .activity__cell {
  height: 40px;
}

.activity__hidden {
  display: none;
}

.activity__table.small .activity__hidden {
  display: block;
}

.activity__table.small .activity__row_head .activity__hidden {
  display: inline;
}

.activity__cell {
  display: table-cell;
  vertical-align: middle;
  height: 80px;
  padding-right: 30px;
}

.activity__cell:first-child {
  padding-left: 25px;
  border-radius: 12px 0 0 12px;
}

.activity__cell:last-child {
  border-radius: 0 12px 12px 0;
}

.activity__table.small .activity__cell:nth-child(3) {
  display: none;
}

.activity__size img {
  min-width: 15px;
}

.activity__company {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: 600;
}

.activity__logo {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  margin-right: 16px;
}

.activity__word_wrap {
  max-width: 200px;
  word-wrap: break-word;
}

.word_wrap {
  word-wrap: break-word;
}

.activity__logo img {
  width: 100%;
  min-height: 100%;
}

.activity__logo .network__logo {
  height: 19px;
  width: 19px;
  min-height: auto;
  position: absolute;
  bottom: 1px;
  margin-left: -12px;
  border-radius: 10px;
}

.activity__table .activity__date,
.activity__transaction {
  font-size: 12px;
  line-height: 1.33333;
  font-weight: 500;
  color: #808191;
}

.activity__price,
.activity__address {
  font-weight: 600;
}

.activity__sidebar {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 364px;
  margin-right: -364px;
  padding: 0 64px 64px 0;
  -webkit-transition: margin 0.2s;
  -o-transition: margin 0.2s;
  transition: margin 0.2s;
}

.activity__sidebar.visible {
  margin-right: 0;
}

.activity__sidebar.visible .activity__wrap.fixed {
  position: fixed;
  top: 60px;
  width: 300px;
}

.activity__wrap {
  position: relative;
}

.activity__logo {
  position: relative;
}

.activity__head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 32px;
  padding-right: 30px;
}

.activity__head .activity__logo {
  width: 42px;
  height: 42px;
  margin-right: 24px;
}

.activity__info {
  margin-bottom: 4px;
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  line-height: 1.33333;
  font-weight: 500;
}

.activity__head .activity__date {
  font-size: 13px;
  line-height: 1.23077;
  font-weight: 500;
  color: #808191;
}

.activity__close {
  position: absolute;
  top: 12px;
  right: 0;
  width: 20px;
  height: 20px;
  font-size: 0;
}

.activity__close .icon {
  font-size: 14px;
  -webkit-transition: -webkit-transform 0.2s;
  transition: -webkit-transform 0.2s;
  -o-transition: transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
}

.activity__close:hover .icon {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.activity__line {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.activity__money {
  font-family: 'Poppins', sans-serif;
  font-size: 40px;
  line-height: 1.2;
  letter-spacing: -1px;
  font-weight: 600;
}

.activity__currency {
  margin-left: 20px;
  padding: 0 13px;
  border-radius: 16px;
  background: #3dbaa2;
  font-size: 16px;
  font-weight: 600;
  line-height: 32px;
  color: #ffffff;
}

.activity__code {
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  line-height: 1.33333;
  font-weight: 500;
  color: #808191;
}

.activity__parameters {
  margin-top: 32px;
}

.activity__parameter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 32px 0;
  border-top: 1px solid #e4e4e4;
}

.activity__preview {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  margin-right: 24px;
  border-radius: 50%;
  background: rgba(228, 228, 228, 0.5);
}

.activity__box {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.activity__category {
  margin-bottom: 8px;
  font-size: 12px;
  line-height: 1.33333;
  font-weight: 500;
  color: #808191;
}

.activity__status {
  display: inline-block;
  padding: 0 8px;
  border-radius: 12px;
  background: rgba(79, 191, 103, 0.2);
  font-size: 12px;
  line-height: 24px;
  font-weight: 500;
  color: #4fbf67;
}

.activity__status.red {
  background: rgba(241, 60, 60, 0.2);
  color: rgb(231, 102, 102);
}

.activity__status.yellow {
  background: rgba(255, 184, 53, 0.2);
  color: rgb(238, 166, 71);
}

.activity__status.green {
  color: #4fbf67;
}

.activity__value {
  font-size: 16px;
  line-height: 1.25;
  font-weight: 600;
  width: 200px;
  word-wrap: break-word;
}

.activity__value a {
  color: inherit;
}

.activity__value a:hover {
  text-decoration: underline;
  text-decoration-color: #8173e0;
}

.dark .activity__tags .activity__link:hover {
  color: #ffffff;
}

.dark .activity__tags .activity__link.active {
  background: #191b20;
  color: #8173e0;
}

.dark .activity__select .activity__icon .icon {
  fill: #ffffff;
}

.dark .activity__row:nth-child(2n + 1) {
  background: rgba(228, 228, 228, 0.03);
}

.dark .activity__close .icon {
  fill: #ffffff;
}

/* .dark .activity__preview {
  background: #e4e4e4;
} */

.dark .activity__parameter {
  border-color: rgba(228, 228, 228, 0.1);
}

.rotate {
  animation: rotation 2s infinite linear;
}

.activity_processing {
  font-family: 'Poppins', sans-serif;
  text-align: center;
}

.activity_processing span {
  font-size: 12px;
}

.activity_processing i {
  font-size: 15px;
  margin-right: 5px;
  margin-bottom: -1px;
}

.withdrawal-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  margin-bottom: 30px;
  margin-top: 23px;
  column-gap: 14px;
  row-gap: 14px;
}

.withdrawal-item {
  font-family: 'Poppins', sans-serif;
  width: 200px;
  height: 120px;
  display: grid;
  place-items: center;
  border-radius: 20px;
  text-align: center;
  padding: 10px;
  box-shadow: 0 15px 35px rgb(0 0 0 / 7%);
}

.withdrawal-instruction {
  width: 100%;
  padding: 10px 0px 10px 0px;
  border-radius: 20px;
  box-shadow: 0 15px 35px rgb(0 0 0 / 7%);
  margin-bottom: 35px;
}

.withdrawal-instruction img {
  width: 35px;
  height: 35px;
}

.withdrawal-instruction .title__container:first-child {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 35px;
}

.dark .withdrawal-instruction {
  background-color: #242731;
}

.withdrawable-container .collaspe_icon {
  font-size: 20px;
}

.withdrawable-container .activity__stage {
  margin-bottom: 0px;
}

.withdrawable-container .collaspe_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  transition: all 0.2s;
  cursor: pointer;
}

.withdrawable-container .collaspe_container.close {
  padding: 5px 20px;
  border-radius: 10px;
  margin-bottom: 40px;
  background-color: rgba(228, 228, 228, 0.3);
}

.dark .withdrawable-container .collaspe_container.close {
  background-color: #242731;
}

.collaspe_container.close .h6 {
  font-size: 18px;
}

.withdrawal-amount {
  font-size: 15px;
  font-weight: 400;
}

.withdrawal-amount span {
  font-size: 14px;
  font-weight: 600;
}
.withdrawable-container .activity__stage {
  margin-bottom: 50px;
  margin-top: -20px;
}

.dark .withdrawal-item {
  background-color: #242731;
}

@keyframes dark-highlight {
  0% {
    background-color: #242731;
  }
  50% {
    background-color: #353842;
  }
  100% {
    background-color: #242731;
  }
}

@keyframes white-highlight {
  0% {
    background-color: #fff;
  }
  50% {
    background-color: rgba(169, 168, 168, 0.4);
  }
  100% {
    background-color: #fff;
  }
}

.withdrawal-list .highlight-container {
  animation: white-highlight 2s infinite;
}
.dark .withdrawal-list .highlight-container {
  animation: dark-highlight 2s infinite;
}

.activity__preview svg {
  width: 17px;
  height: 17px;
}

.activity__preview svg path {
  fill: #000;
}

.dark .activity__preview svg path {
  fill: #fff;
}

.dark .activity__preview {
  background-color: #242731;
}

@media only screen and (max-width: 600px) {
  .withdrawal-item {
    width: 100%;
    margin-bottom: 10px;
    margin-right: 0px;
    height: 180px;
  }
  .withdrawal-list {
    place-items: center;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    margin-bottom: 30px;
    margin-top: 23px;
  }
  .withdrawable-container .activity__stage {
    margin-bottom: 50px;
    margin-top: 0px;
  }
  .dark .activity__preview {
    background-color: #191b20;
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
