.wallets {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.wallets__container {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  padding: 0 64px 64px;
}

.vl {
  border-left: 1px solid #808191;
  height: 15px;
  margin-left: 8px;
  margin-right: 8px;
}

.total__balance_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
  margin-left: 4px;
}

.total__balance_item {
  font-size: 12px !important;
  display: flex;
  flex-direction: row;
  align-items: end;
}

.total__balance_wrapper.column {
  flex-direction: column;
  align-items: start;
}

.total__balance_wrapper.column .vl {
  display: none;
}

.total__balance_wrapper.column .total__balance_item:last-child {
  margin-top: 6px;
}

.wallets__title_icon {
  cursor: pointer;
}

.wallets__sidebar {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 364px;
  margin-right: -364px;
  padding: 0 64px 64px 0;
  -webkit-transition: margin 0.2s;
  -o-transition: margin 0.2s;
  transition: margin 0.2s;
}

.wallets__sidebar.visible {
  margin-right: 0;
}

.wallets__wrapper {
  position: relative;
  margin-bottom: 68px;
  padding: 32px;
  border-radius: 24px;
  background: #ffffff;
}

.wallets__wrapper:before,
.wallets__wrapper:after {
  content: '';
  position: absolute;
  border-radius: 24px;
}

.wallets__wrapper:before {
  top: 20px;
  left: 8px;
  right: 8px;
  bottom: -20px;
  z-index: -2;
  background: #e4e4e4;
  -webkit-filter: blur(86.985px);
  filter: blur(86.985px);
}

.wallets__wrapper:after {
  top: 10px;
  left: 10px;
  right: 10px;
  bottom: -10px;
  z-index: -1;
  background: rgba(255, 255, 255, 0.5);
}

.wallets__row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  /* align-items: flex-end; */
  align-items: center;
}

.wallets__total {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  padding-right: 20px;
}

.wallets__group {
  position: absolute;
  right: 32px;
  left: 640px;
  bottom: 64px;
}

.wallets__wrapper.small .wallets__group {
  position: static;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 260px;
}

.withdraw_popup {
  padding: 50px 0px 21px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.withdraw_popup_title {
  margin-bottom: 32px;
  text-align: center;
  max-width: 500px;
}

.wallets__wrapper .wallets__box:not(:last-child) {
  margin-bottom: 24px;
}

.wallets__wrapper .wallets__info {
  margin-bottom: 4px;
  font-size: 12px;
  line-height: 1.33333;
  font-weight: 500;
  color: #808191;
  width: fit-content;
}

.wallets__group .wallets__info {
  margin-bottom: 8px;
}

.wallets__info .icon {
  width: 12px;
  margin-left: 3px;
  margin-top: -1px;
  cursor: pointer;
}

.wallets__progress {
  height: 12px;
  border-radius: 6px;
}

.wallets__btns {
  position: absolute;
  bottom: 32px;
  right: 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.wallets__wrapper.small .wallets__btns {
  display: none;
}

.wallets__btns .wallets__btn:not(:last-child) {
  margin-right: 6px;
}

.wallets__title {
  margin-bottom: 12px;
}

.wallets__flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 8px;
}

.wallets__currency {
  display: inline-block;
  padding: 6px 19px;
  border-radius: 16px;
  font-size: 16px;
  line-height: 1.25;
  font-weight: 600;
  color: #ffffff;
}

.wallets__currency:not(:first-child) {
  margin-left: 20px;
}

.wallets__wrapper .wallets__currency {
  background: #ff9f38;
}

.wallets__inner {
  max-width: 536px;
  margin-top: 50px;
}

.wallets__wrapper.small .wallets__inner {
  max-width: 100%;
}

.wallets__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: -24px -12px 0;
}

.wallets__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 calc(50% - 24px);
  flex: 0 0 calc(50% - 24px);
  width: calc(50% - 24px);
  margin: 24px 12px 0;
  padding: 24px;
  border-radius: 16px;
  border: 1px solid #e4e4e4;
}

.wallets__icon {
  margin-right: 12px;
}

.wallets__details {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.wallets__price {
  margin-top: 2px;
  font-weight: 600;
  color: #4fbf67;
}

.wallets__price.small {
  font-size: 12px;
  font-family: 'Poppins', sans-serif;
}

.wallets__wrap {
  position: relative;
}

.wallets__head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 32px;
  padding-right: 30px;
}

.wallets__head .wallets__logo {
  width: 42px;
  height: 42px;
  margin-right: 16px;
}

.wallets__head .wallets__info {
  margin-bottom: 4px;
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  line-height: 1.33333;
  font-weight: 500;
}

.wallets__company {
  font-size: 13px;
  line-height: 1.23077;
  font-weight: 500;
  color: #808191;
}

.wallets__close {
  position: absolute;
  top: 12px;
  right: 0;
  width: 20px;
  height: 20px;
  font-size: 0;
}

.wallets__close .icon {
  font-size: 14px;
  -webkit-transition: -webkit-transform 0.2s;
  transition: -webkit-transform 0.2s;
  -o-transition: transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
}

.wallets__close:hover .icon {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.wallets__line {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.wallets__money {
  font-family: 'Poppins', sans-serif;
  font-size: 35px;
  line-height: 1.2;
  letter-spacing: -1px;
  font-weight: 600;
}

.wallets__wrap .wallets__currency {
  background: #6c5dd3;
}

.wallets__code {
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  line-height: 1.33333;
  font-weight: 500;
  color: #808191;
}

/* .wallets__parameters {
  margin-top: 22px;
} */

.wallets__parameter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 32px 0;
  border-top: 1px solid #e4e4e4;
}

.wallets__parameter:last-child {
  padding-bottom: 0;
}

.wallets__preview {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 48px;
  height: 48px;
  margin-right: 32px;
  border-radius: 50%;
}

.wallets__preview svg {
  width: 20px;
  height: 20px;
}

.wallets__preview svg path {
  fill: #000;
}

.dark .wallets__preview svg path {
  fill: #fff;
}

.wallets__preview {
  background-color: rgba(228, 228, 228, 0.5);
}

.dark .wallets__preview {
  background-color: #242731;
}

.wallets__parameter .wallets__box {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.wallets__category {
  margin-bottom: 6px;
  font-size: 12px;
  line-height: 1.33333;
  font-weight: 500;
  color: #808191;
}

.wallets .card {
  margin-top: 48px;
}

.dark .wallets__wrapper {
  background: #242731;
}

.dark .wallets__wrapper:before {
  display: none;
}

.dark .wallets__wrapper:after {
  top: 10px;
  left: 10px;
  right: 10px;
  bottom: -10px;
  z-index: -1;
  background: rgba(36, 39, 49, 0.5);
}

.dark .wallets__item {
  border-color: rgba(228, 228, 228, 0.1);
}

.dark .wallets__close .icon {
  fill: #ffffff;
}

.history_filter_item:hover {
  background: transparent !important;
}

.history_filter_item {
  padding: 10px 10px 4px 18px !important;
}

.exchange__item:not(:last-child).history_filter_item {
  margin-bottom: 0px !important;
}

.dark .wallets__parameter {
  border-color: rgba(228, 228, 228, 0.1);
}

.balances__title {
  margin-bottom: 32px;
}

.balances__table {
  display: table;
  width: 100%;
}

.balances__row {
  display: table-row;
  border-radius: 12px;
  cursor: pointer;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.balances__row:nth-child(2n + 1) {
  background: rgba(228, 228, 228, 0.2);
}

.balances__row:not(.balances__row_head):hover {
  background: none;
  -webkit-box-shadow: 0 0 0 2px #6c5dd3;
  box-shadow: 0 0 0 2px #6c5dd3;
}

.balances__cell {
  display: table-cell;
  vertical-align: middle;
  padding-left: 24px;
}

.balances__cell:first-child {
  border-radius: 12px 0 0 12px;
  font-size: 0;
}

.balances__cell:nth-child(3) {
  font-weight: 600;
  color: #808191;
}

.balances__cell:last-child {
  padding-right: 24px;
  border-radius: 0 12px 12px 0;
}

/* .balances__table.small .balances__cell:nth-child(4),
.balances__table.small .balances__cell:nth-child(5) {
  display: none;
} */

.balances__row_head .balances__cell {
  height: 40px;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #808191;
}

.balances__row_head .balances__cell:first-child {
  border-radius: 8px 0 0 8px;
}

.balances__row_head .balances__cell:last-child {
  border-radius: 0 8px 8px 0;
}

.balances__row:not(.balances__row_head) .balances__cell {
  height: 80px;
}

.balances__company {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: 600;
}

.balances__logo {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  margin-right: 16px;
}

.balances__logo img {
  width: 100%;
  min-height: 100%;
  border-radius: 50%;
}

.balances__number {
  font-weight: 600;
}

.balances__price {
  font-size: 12px;
  line-height: 1.33333;
  font-weight: 500;
  color: #808191;
}

.balances__btns {
  margin-top: 32px;
  text-align: center;
}

.dark .balances__row:nth-child(2n + 1) {
  background: rgba(228, 228, 228, 0.04);
}

.operations__form,
.operations__wrap {
  display: none;
}

.enable__force_withdrawal .icon {
  width: 14px;
  margin-top: -2px;
}

.enable__force_withdrawal .checkbox__tick {
  width: 15px;
  height: 15px;
}

.operations__field,
.operations__balance,
.operations__action {
  margin-bottom: 16px;
}

.operations__balance {
  padding: 20px 22px;
  border-radius: 12px;
  background: rgba(79, 191, 103, 0.2);
}

.operations__label {
  font-size: 10px;
  font-weight: 700;
  line-height: 1.6;
  letter-spacing: 0.9px;
  text-transform: uppercase;
  color: #808191;
}

.operations__form .operations__counter {
  margin-bottom: 2px;
  font-weight: 600;
}

.operations__counter {
  font-family: 'Poppins', sans-serif;
}

.operations__price {
  font-size: 12px;
  line-height: 1.33333;
  font-weight: 500;
  color: #808191;
}

.operations__action .operations__field {
  margin-bottom: 0;
}

.operations__action .operations__field .field__input {
  border-radius: 12px 12px 0 0;
}

.operations__note {
  padding: 0 20px;
  background-color: rgba(108, 93, 211, 0.1);
  /* border: rgba(106, 94, 204, 1) 1px solid; */
  border-radius: 0 0 12px 12px;
  font-size: 12px;
  font-weight: 500;
  line-height: 40px;
  color: #000;
}

.operations__note h5 {
  font-family: 'Poppins', sans-serif;
  font-size: 11.5px;
}

.dark .operations__note {
  color: #fff;
}

.operations__item {
  padding: 24px;
  border-radius: 24px;
  background: #ffffff;
  -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
}

.operations__item:not(:last-child) {
  margin-bottom: 24px;
}

.operations__flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.operations__item .operations__box {
  text-align: right;
}

.operations__item .operations__counter {
  font-family: 'Poppins', sans-serif !important;
  font-size: 14px;
  line-height: 1.33333;
  font-weight: 500;
}

.operations__title {
  font-family: 'Poppins', sans-serif;
  font-size: 15px;
  line-height: 1.33333;
  font-weight: 500;
}

.operations__item > .operations__title {
  margin-bottom: 8px;
}

.operations__text {
  margin-bottom: 32px;
  font-size: 13px;
  line-height: 1.23077;
  font-weight: 500;
  color: #808191;
}

.operations__text strong {
  font-weight: 400;
  color: #11142d;
}

.operations__code {
  position: relative;
  margin-bottom: 36px;
}

.operations__input {
  width: 100%;
  height: 48px;
  padding: 0 48px 0 16px;
  border-radius: 12px;
  background: rgba(228, 228, 228, 0.3);
  font-size: 12px;
  line-height: 1.33333;
  font-weight: 500;
  color: #11142d;
}

.operations__copy {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 48px;
  font-size: 0;
}

.operations__copy .icon {
  font-size: 14px;
  fill: #6c5dd3;
  -webkit-transition: opacity 0.2s;
  -o-transition: opacity 0.2s;
  transition: opacity 0.2s;
}

.operations__copy:hover .icon {
  opacity: 0.7;
}

.operations__preview {
  max-width: 105px;
  margin: 0 auto;
  padding-bottom: 10px;
}

.operations__preview img {
  width: 100%;
}

.operations__info {
  max-width: 200px;
  margin: 0 auto;
  text-align: center;
  font-size: 13px;
  line-height: 1.23077;
  font-weight: 500;
  color: #808191;
}

.operations__info a {
  color: #8173e0;
}

.operations__info a:hover {
  text-decoration: none;
}

.dark .operations__item {
  background: #242731;
}

.dark .operations__input {
  background: rgba(228, 228, 228, 0.03);
  color: #ffffff;
}

.dark .operations__text strong {
  color: #ffffff;
}

.withdraw__popup__container {
  padding: 10px 15px 10px 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
}

.withdraw__popup__title {
  width: 100%;
  margin-top: 50px;
  padding: 20px;
  border: #b4b6cc solid 1px;
  border-radius: 20px;
}

.withdraw__body {
  padding: 0px 40px 0px 40px;
}

.withdraw__popup__btn {
  margin-top: 42px;
}

.wallets_search__container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.wallets_search__container input {
  max-width: 180px;
}

.warning_kyc_2fa_wallet {
  background-color: rgb(240, 209, 209, 0.5);
  color: rgb(241, 98, 98);
  font-family: 'Poppins', sans-serif;
  padding: 10px;
  margin: 15px 0px;
  border-radius: 10px;
  font-size: 12px;
}

.dark .warning_kyc_2fa_wallet {
  background-color: rgb(240, 209, 209);
}

.warning_kyc_2fa_wallet span {
  cursor: pointer;
  text-decoration: underline;
}

.comparison-whole-container {
  /* border: 1px solid #6c5dd3; */
  margin: 7px 0px;
  padding: 12px;
  border-radius: 20px;
  box-shadow: 0 8px 25px rgb(0 0 0 / 7%);
}
.comparison_btn {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.comparison_btn .btn {
  font-size: 12px;
  padding: 0px 10px;
  width: fit-content;
  height: 40px;
}
.dark .comparison-whole-container {
  background-color: #242731;
}

.comparison-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.comparison-title {
  font-size: 17px;
  margin: 12px 0px;
  text-align: center;
}

.comparison .h5 {
  font-size: 14px;
  color: #8173e0;
  text-align: center;
  margin-bottom: 12px;
}

.comparison {
  background-color: rgba(228, 228, 228, 0.3);
  padding: 10px 20px;
  border-radius: 20px;
  flex: 1;
  margin: 10px 0px;
}

.comparison li {
  font-size: 10px;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  padding: 5px 0px;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
}

.comparison li i {
  margin-right: 8px;
}

.comparison:last-child {
  margin-left: 12px;
}

.dark .comparison {
  background-color: #191b20;
}

.Toastify__toast-body {
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  z-index: 10000;
}

.deposit_chart_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: -16px;
}

.latest_history {
  cursor: pointer;
}

.latest_history:hover {
  opacity: 0.6;
}

.free_usdc {
  font-family: 'Poppins';
  max-width: 250px;
  justify-content: space-between;
  align-items: center;
  /* margin: 0 4px -7px; */
  background-color: rgba(108, 93, 211, 0.1);
  border: 1px #6c5dd3 solid;
  padding: 5px 20px 5px 20px;
  border-radius: 5px;
  font-size: 10px;
  position: absolute;
  top: 20px;
  right: 20px;
}

.free_usdc p span,
.free_usdc p a {
  text-decoration: underline;
  color: #8173e0;
  margin-left: 4px;
  cursor: pointer;
  margin-top: 12px;
  margin-bottom: 12px;
}

.wallet_coin__dropdown {
  display: grid;
  grid-template-columns: 150px 150px;
}

.history_filter_dropdown {
  grid-template-columns: 110px 110px;
}

.wallet_deposit_container {
  display: flex;
  justify-content: flex-end;
}

.simplex-text h4 {
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
  text-align: center;
  margin-bottom: 12px;
  text-decoration: underline;
  text-decoration-color: #8173e0;
  color: #8173e0;
  cursor: pointer;
  max-width: 250px;
}

.flex_center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dark .warning__box {
  color: #ff9f38;
  transition: all 3s;
}

.warning__box {
  color: #885218;
  text-align: left;
  transition: all 3s;
}

.warning__box h1 {
  font-size: 16px;
  font-family: 'Poppins';
}
.warning__box .link {
  font-size: 12px;
  font-family: 'Poppins';
  text-decoration: underline;
  color: #000;
  cursor: pointer;
  margin-top: 20px;
}

.dark .warning__box .link {
  color: #fff;
}

.estimated__gas {
  font-family: 'Poppins';
  /* text-align: right; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: 20px;
  padding: 0px 2px;
  color: #808191;
}

.estimated__gas .h6 {
  font-size: 13px;
}

.estimated__gas .gas_amount {
  color: #4fbf67;
}

.step-track {
  margin-top: 2rem;
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
}
.step-track-step {
  display: flex;
  height: 3.2rem;
  counter-increment: stepper;
}
.step-track-step:last-child {
  overflow: hidden;
  height: 3rem;
}
.step-track-step:last-child .step-track-status span:last-of-type {
  display: none;
}
.step-track-status {
  margin-right: 1.5rem;
  position: relative;
  font-family: 'Poppins', sans-serif;
}

.step-track-status-dot {
  display: block;
  width: 2.2rem;
  height: 2.2rem;
  border-radius: 50%;
  background: #6c5dd3;
  position: relative;
}
.step-track-status-line {
  display: block;
  margin: 0 auto;
  border-left: 2px dashed #6c5dd3;
  height: 3.2rem;
  position: absolute;
  left: 16px;
  /* background: #6c5dd3; */
}
.step-track-status-dot::after {
  content: counter(stepper);
  position: absolute;
  top: 21%;
  left: 40%;
  font-size: 12px;
  font-family: 'Poppins', sans-serif;
  color: #fff;
}

.step-track-status-dot.stopwatch {
  background: transparent;
}

.step-track-status-dot.stopwatch::after {
  content: url(../../assests/img/stopwatch.png);
  width: 10px;
  height: 10px;
  position: absolute;
  top: -9%;
  left: 16%;
  font-size: 29px;
  font-family: 'Poppins', sans-serif;
  color: #fff;
  z-index: 100;
}

.step-track-text-stat {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 3px;
  font-family: 'Poppins', sans-serif;
}
.step-track-text-sub {
  font-size: 10px;
  font-weight: 100;
}

.step-track {
  transition: all 0.3s height 0.3s;
  transform-origin: top center;
}

.static__wallet_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.static__wallet_container img {
  width: 400px;
}

.static__wallet_container div:last-child {
  margin-left: 50px;
}

.static__wallet_container .h6 {
  margin-bottom: 25px;
  margin-top: 25px;
  font-size: 16px;
  max-width: 90%;
}

.wallet-analytics {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 15px;
}

.wallet-analytics-item {
  margin-top: 20px;
  margin-bottom: 15px;
  width: 50%;
}

.wallet-analytics-item .wallets__category {
  font-size: 13px;
}

.wallet-analytics-item:nth-child(even) {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.wallet-analytics-item .wallet-money {
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
}

.deposit__card {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  background-color: rgba(108, 93, 211, 0.1);
  border: rgba(106, 94, 204, 1) 1px solid;
  /* justify-content: center;
  align-items: center; */
  padding: 12px 20px;
  border-radius: 15px;
  margin-bottom: 20px;
}

.deposit__card img {
  width: 60px;
}

.deposit__card .subtitle.h6 {
  font-size: 12px;
  font-family: 'Poppins', sans-serif;
  color: rgba(128, 129, 144, 1);
  max-width: 190px;
}

.deposit__card .title.h6 {
  font-size: 13px;
  font-family: 'Poppins', sans-serif;
  max-width: 170px;
  letter-spacing: 0.1px;
}

.deposit__card .deposit__card_item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.deposit__card .deposit__card_item:nth-child(2) {
  margin-top: 10px;
}

.deposit__card_item .usdc {
  font-size: 11px;
  font-family: 'Poppins', sans-serif;
  color: rgba(128, 129, 144, 1);
}

.deposit__card_item .amount {
  font-size: 14px;
}

.estimated__gas {
  font-family: 'Poppins';
  /* text-align: right; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: 20px;
  padding: 0px 2px;
  color: #808191;
}

.estimated__gas .h6 {
  font-size: 13px;
}

.estimated__gas .gas_amount {
  color: #4fbf67;
}

.allowance__deposit_container p {
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  margin-top: 6px;
}

.allowance__deposit_container p span {
  color: #8173e0;
}

.allowance__deposit_container .step-track-status {
  margin-right: 1rem;
}

.allowance__deposit_container .image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.allowance__deposit_container .image-container img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: #191b20;
}

.allowance__deposit_container .image-container img:nth-child(2) {
  margin-left: -20px;
  overflow: hidden;
  background-color: transparent;
}

.wallets__parameter:first-child {
  border-top: none;
}

.withdraw__instruction .h6 {
  text-align: center;
}

.withdraw__instruction p {
  font-size: 14px;
}

.allowance__msg {
  color: #808191;
  font-size: 12.5px;
  text-align: center;
}

.allowance__msg span {
  color: #000;
}

.dark .allowance__msg span {
  color: #fff;
}

.network__switch_toast h2 {
  font-size: 14px;
}

.network__switch_toast p {
  font-size: 12px;
  color: #808191;
}

@media only screen and (max-width: 1339px) {
  .wallet_coin__dropdown {
    display: grid;
    grid-template-columns: 150px;
  }
  .wallet_deposit_container {
    justify-content: flex-start;
    width: fit-content;
  }
  .exchange__company.active .wallet_body {
    width: 200px;
  }
  .dark .wallets__preview {
    background-color: #191b20;
  }
}

.login__wallet_remember_me .checkbox__tick {
  position: relative;
  flex-shrink: 0;
  width: 15px;
  height: 15px;
  border-radius: 4px;
  border: 1.7px solid #e1e1e1;
  transition: all 0.25s;
  margin-top: 1.9px;
}

@media only screen and (max-width: 700px) {
  .free_usdc {
    position: relative;
    margin: 20px 10px;
  }
  .stepper-line {
    height: 150px;
  }
}
