.dark .apexcharts-tooltip.apexcharts-theme-light {
  color: #11142d;
}

.dark .apexcharts-gridlines-horizontal line {
  stroke: rgba(228, 228, 228, 0.1);
}

.apexcharts-toolbar {
  display: none !important;
}
