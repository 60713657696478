html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

img,
fieldset,
a img {
  border: none;
}

input[type='text'],
input[type='email'],
input[type='tel'],
textarea {
  -webkit-appearance: none;
}

input[type='submit'],
button {
  cursor: pointer;
  background: none;
}

.popup__nav i {
  display: none;
}

input[type='submit']::-moz-focus-inner,
button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

textarea {
  overflow: auto;
}

input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input,
button {
  margin: 0;
  padding: 0;
  border: 0;
}

div,
input,
textarea,
select,
button,
h1,
h2,
h3,
h4,
h5,
h6,
a,
span,
a:focus {
  outline: none;
}

select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

ul,
ol {
  list-style-type: none;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
}

html {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

a {
  text-decoration: none;
}

a,
button {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  min-width: 375px;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  line-height: 1.71429;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #11142d;
}

.dark {
  background: #1f2128;
  color: #ffffff;
}

body.no-scroll,
html.no-scroll {
  overflow: hidden;
}

button,
input,
textarea,
select {
  font-family: 'Inter', sans-serif;
}

img {
  max-width: 100%;
  vertical-align: middle;
}

.nice-select {
  -webkit-tap-highlight-color: transparent;
  background-color: #fff;
  border-radius: 5px;
  border: solid 1px #e8e8e8;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  clear: both;
  cursor: pointer;
  display: block;
  float: left;
  font-family: inherit;
  font-size: 14px;
  font-weight: normal;
  height: 42px;
  line-height: 40px;
  outline: none;
  padding-left: 18px;
  padding-right: 30px;
  position: relative;
  text-align: left !important;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap;
  width: auto;
}

.nice-select:hover {
  border-color: #dbdbdb;
}

.nice-select:active,
.nice-select.open,
.nice-select:focus {
  border-color: #999;
}

.nice-select:after {
  border-bottom: 2px solid #999;
  border-right: 2px solid #999;
  content: '';
  display: block;
  height: 5px;
  margin-top: -4px;
  pointer-events: none;
  position: absolute;
  right: 12px;
  top: 50%;
  -webkit-transform-origin: 66% 66%;
  -ms-transform-origin: 66% 66%;
  transform-origin: 66% 66%;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  width: 5px;
}

.nice-select.open:after {
  -webkit-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.nice-select.open .list {
  opacity: 1;
  pointer-events: auto;
  -webkit-transform: scale(1) translateY(0);
  -ms-transform: scale(1) translateY(0);
  transform: scale(1) translateY(0);
}

.nice-select.disabled {
  border-color: #ededed;
  color: #999;
  pointer-events: none;
}

.nice-select.disabled:after {
  border-color: #cccccc;
}

.nice-select.wide {
  width: 100%;
}

.nice-select.wide .list {
  left: 0 !important;
  right: 0 !important;
}

.nice-select.right {
  float: right;
}

.nice-select.right .list {
  left: auto;
  right: 0;
}

.nice-select.small {
  font-size: 12px;
  height: 36px;
  line-height: 34px;
}

.nice-select.small:after {
  height: 4px;
  width: 4px;
}

.nice-select.small .option {
  line-height: 34px;
  min-height: 34px;
}

.nice-select .list {
  background-color: #fff;
  border-radius: 5px;
  -webkit-box-shadow: 0 0 0 1px rgba(68, 68, 68, 0.11);
  box-shadow: 0 0 0 1px rgba(68, 68, 68, 0.11);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-top: 4px;
  opacity: 0;
  overflow: hidden;
  padding: 0;
  pointer-events: none;
  position: absolute;
  top: 100%;
  left: 0;
  -webkit-transform-origin: 50% 0;
  -ms-transform-origin: 50% 0;
  transform-origin: 50% 0;
  -webkit-transform: scale(0.75) translateY(-21px);
  -ms-transform: scale(0.75) translateY(-21px);
  transform: scale(0.75) translateY(-21px);
  -webkit-transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25),
    opacity 0.15s ease-out;
  -o-transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
  transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
  z-index: 9;
}

.nice-select .list:hover .option:not(:hover) {
  background-color: transparent !important;
}

.nice-select .option {
  cursor: pointer;
  font-weight: 400;
  line-height: 40px;
  list-style: none;
  min-height: 40px;
  outline: none;
  padding-left: 18px;
  padding-right: 29px;
  text-align: left;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.nice-select .option:hover,
.nice-select .option.focus,
.nice-select .option.selected.focus {
  background-color: #f6f6f6;
}

.nice-select .option.selected {
  font-weight: bold;
}

.nice-select .option.disabled {
  background-color: transparent;
  color: #999;
  cursor: default;
}

.no-csspointerevents .nice-select .list {
  display: none;
}

.no-csspointerevents .nice-select.open .list {
  display: block;
}

.select {
  height: 64px;
  padding-left: 20px;
  padding-right: 45px;
  background: rgba(228, 228, 228, 0.3);
  border-radius: 16px;
  border: none;
  float: none;
  line-height: 64px;
  font-weight: 600;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.select:after {
  display: none;
}

.select:before {
  content: '';
  position: absolute;
  top: 50%;
  right: 20px;
  width: 12px;
  height: 7px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='8' fill='none' viewBox='0 0 12 8'%3E%3Cpath d='M10.281.305L5.988 4.598 1.695.305A1 1 0 0 0 .293.317a1 1 0 0 0-.012 1.402l5 5a1 1 0 0 0 1.414 0l5-5a1 1 0 0 0-.441-1.687 1 1 0 0 0-.973.273z' fill='%2311142d'/%3E%3C/svg%3E")
    no-repeat 50% 50% / auto 100%;
  -webkit-transition: -webkit-transform 0.2s;
  transition: -webkit-transform 0.2s;
  -o-transition: transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
}

.select.open:before {
  -webkit-transform: translateY(-50%) rotate(180deg);
  -ms-transform: translateY(-50%) rotate(180deg);
  transform: translateY(-50%) rotate(180deg);
}

.select:not(.select_empty).open {
  -webkit-box-shadow: inset 0 0 0 2px #6c5dd3;
  box-shadow: inset 0 0 0 2px #6c5dd3;
  background: #ffffff;
}

.select .list {
  right: 0;
  margin-top: 7px;
  padding: 24px;
  border-radius: 20px;
  border: 1px solid #e4e4e4;
  -webkit-box-shadow: 0 5px 25px rgba(0, 0, 0, 0.07);
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.07);
}

.select .option {
  position: relative;
  padding-left: 28px;
  min-height: auto;
  line-height: 1.71429;
  font-weight: 600;
  color: #808191;
  -webkit-transition: color 0.2s;
  -o-transition: color 0.2s;
  transition: color 0.2s;
}

.select .option:before {
  content: '';
  position: absolute;
  top: 5px;
  left: 0;
  width: 16px;
  height: 13px;
  background: url("data:image/svg+xml,%3Csvg width='16' height='14' viewBox='0 0 16 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.6 8.60001L2.4 5.40001L0 7.80001L5.6 13.4L16 3.00001L13.6 0.600006L5.6 8.60001Z' fill='%23355DFF'/%3E%3C/svg%3E%0A")
    no-repeat 50% 50%/100% auto;
  opacity: 0;
  -webkit-transition: opacity 0.2s;
  -o-transition: opacity 0.2s;
  transition: opacity 0.2s;
}

.select .option:hover,
.select .option.focus,
.select .option.selected.focus {
  background: none;
}

.select .option:hover {
  color: #11142d;
}

.select .option.selected {
  color: #8173e0;
}

.select .option.selected:before {
  opacity: 1;
}

.select .option:not(:last-child) {
  margin-bottom: 8px;
}

.select_sm {
  height: 56px;
  line-height: 56px;
  border-radius: 8px;
}

.select_empty {
  padding-left: 0;
  padding-right: 32px;
  height: 24px;
  background: none;
  border-radius: 0;
  line-height: 24px;
}

.select_empty:before {
  right: 8px;
}

.select_empty .list {
  left: auto;
  right: -16px;
  width: 192px;
  margin-top: 19px;
}

.select_arrows:before {
  width: 12px;
  height: 16px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='18' viewBox='0 0 12 18'%3E%3Cpath fill='%231b1d21' d='M11.247 11.836a1 1 0 0 1 .006 1.323l-.089.089-4.5 4a1 1 0 0 1-1.23.077l-.099-.077-4.5-4a1 1 0 0 1 1.23-1.572l.099.078L6 15.161l3.836-3.408a1 1 0 0 1 1.412.083zM6.566.675l.099.077 4.5 4a1 1 0 0 1-1.23 1.572l-.099-.078L6 2.838 2.164 6.247a1 1 0 0 1-1.323.006l-.089-.089a1 1 0 0 1-.006-1.323l.089-.089 4.5-4a1 1 0 0 1 1.23-.077z'/%3E%3C/svg%3E");
}

.select_arrows .list {
  margin-top: 2px;
  padding: 12px 19px;
  border-radius: 8px;
}

.select_arrows .option {
  padding-left: 0;
}

.select_arrows .option:before {
  display: none;
}

select.select {
  opacity: 0;
}

.dark .select:not(.select_empty) {
  background: rgba(228, 228, 228, 0.03);
}

.dark .select:before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='8' fill='none' viewBox='0 0 12 8'%3E%3Cpath d='M10.281.305L5.988 4.598 1.695.305A1 1 0 0 0 .293.317a1 1 0 0 0-.012 1.402l5 5a1 1 0 0 0 1.414 0l5-5a1 1 0 0 0-.441-1.687 1 1 0 0 0-.973.273z' fill='%23ffffff'/%3E%3C/svg%3E");
}

.dark .select .list {
  border-color: rgba(228, 228, 228, 0.1);
  background: #191b20;
}

.dark .select .option:hover {
  color: #ffffff;
}

.dark .select .option.selected {
  color: #8173e0;
}

.dark .select_arrows:before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='18' viewBox='0 0 12 18'%3E%3Cpath fill='%23ffffff' d='M11.247 11.836a1 1 0 0 1 .006 1.323l-.089.089-4.5 4a1 1 0 0 1-1.23.077l-.099-.077-4.5-4a1 1 0 0 1 1.23-1.572l.099.078L6 15.161l3.836-3.408a1 1 0 0 1 1.412.083zM6.566.675l.099.077 4.5 4a1 1 0 0 1-1.23 1.572l-.099-.078L6 2.838 2.164 6.247a1 1 0 0 1-1.323.006l-.089-.089a1 1 0 0 1-.006-1.323l.089-.089 4.5-4a1 1 0 0 1 1.23-.077z'/%3E%3C/svg%3E");
}

.dark .select.open {
  background: none;
}

.title {
  font-family: 'Poppins', sans-serif;
  font-size: 72px;
  line-height: 1.11111;
  font-weight: 600;
  letter-spacing: -1px;
}

.title_sm {
  font-size: 64px;
  line-height: 1.125;
}

.title_big {
  font-size: 96px;
  line-height: 1.16667;
}

.h1,
.h2,
.h3 {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
}

.h4,
.h5,
.h6 {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
}

.h1 {
  font-size: 56px;
  line-height: 1.14286;
  letter-spacing: -1px;
}

.h2 {
  font-size: 48px;
  line-height: 1.16667;
  letter-spacing: -1px;
}

.h3 {
  font-size: 40px;
  line-height: 1.2;
  letter-spacing: -1px;
}

.h4 {
  font-size: 32px;
  line-height: 1.25;
  letter-spacing: -0.5px;
}

.h5 {
  font-size: 24px;
  line-height: 1.33333;
}

.h6 {
  font-size: 18px;
  line-height: 1.33333;
}
.fill-green {
  color: #4fbf67;
}

.fill-red {
  color: #ff6628;
}

.color-green {
  color: #4fbf67;
}

.color-blue {
  color: #8173e0;
}

.color-red {
  color: #ff5353;
}

.color-gray {
  color: #808191;
}

.color-orange {
  color: #ff9f38;
}

.bg-blue {
  background: #6c5dd3;
}

.bg-blue-light {
  background: #6c5dd3;
}

.bg-blue-gradient {
  background: -webkit-linear-gradient(
    303.71deg,
    #b7eaff 14.84%,
    #57b1e4 92.53%
  );
  background: -o-linear-gradient(303.71deg, #b7eaff 14.84%, #57b1e4 92.53%);
  background: linear-gradient(146.29deg, #b7eaff 14.84%, #57b1e4 92.53%);
}

.bg-green {
  background: #4fbf67;
}

.bg-green-opacity {
  background: rgba(79, 191, 103, 0.15);
}

.bg-green-gradient {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#86e1a0),
    to(#4fbf67)
  );
  background: -webkit-linear-gradient(top, #86e1a0 0%, #4fbf67 100%);
  background: -o-linear-gradient(top, #86e1a0 0%, #4fbf67 100%);
  background: linear-gradient(180deg, #86e1a0 0%, #4fbf67 100%);
}

.bg-orange {
  background: #ff9f38;
}

.bg-orange-gradient {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#ffce6a),
    to(#ff9f38)
  );
  background: -webkit-linear-gradient(top, #ffce6a 0%, #ff9f38 100%);
  background: -o-linear-gradient(top, #ffce6a 0%, #ff9f38 100%);
  background: linear-gradient(180deg, #ffce6a 0%, #ff9f38 100%);
}

.bg-purple {
  background: #6c5dd3;
}

.bg-purple-gradient {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#a596eb),
    to(#6c5dd3)
  );
  background: -webkit-linear-gradient(top, #a596eb 0%, #6c5dd3 100%);
  background: -o-linear-gradient(top, #a596eb 0%, #6c5dd3 100%);
  background: linear-gradient(180deg, #a596eb 0%, #6c5dd3 100%);
}

.bg-pink {
  background: #ff98e5;
}

.bg-pink-gradient {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#ffc8f4),
    to(#ff98e5)
  );
  background: -webkit-linear-gradient(top, #ffc8f4 0%, #ff98e5 100%);
  background: -o-linear-gradient(top, #ffc8f4 0%, #ff98e5 100%);
  background: linear-gradient(180deg, #ffc8f4 0%, #ff98e5 100%);
}

.bg-red {
  background: #ff6628;
}

.bg-red-opacity {
  background: rgba(255, 102, 40, 0.15);
}

.positive {
  color: #4fbf67;
}

.negative {
  color: #ff7a68 !important;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-uppercase {
  text-transform: uppercase;
}

.m-auto {
  margin: auto;
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

.align-baseline {
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
}

.desktop-show {
  display: none !important;
}

.tablet-show {
  display: none !important;
}

.mobile-show {
  display: none !important;
}

.kyc_stage__title > * {
  margin-bottom: 10px;
  font-size: 12px;
}

.error-message {
  color: #ff7a68;
  text-align: center;
  margin-bottom: 10;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

button:disabled,
button[disabled] {
  opacity: 0.7;
}

button.actions__btn:disabled,
button.actions__btn[disabled] {
  opacity: 0.4;
}

.overflow-hidden {
  overflow: hidden;
}

.btn-small {
  min-width: 87px !important;
  height: 40px !important;
  padding: 0 17px !important;
  border-radius: 10px !important;
  font-size: 13px !important;
}

.color-yellow {
  color: rgb(255, 208, 0);
}

.timer_countdown {
  min-height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  font-family: 'Poppins', sans-serif;
  text-align: center;
}

.timer_countdown h1 {
  font-size: 40px;
}

.countdown {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center;
  flex-wrap: wrap;
}

.countdown .dot {
  color: #8173e0;
  font-size: 60px;
  margin-top: -23px;
}

.countdown h2 {
  font-weight: 400;
}

.countdown .info {
  font-size: 15px;
  text-align: center;
  margin-top: -30px;
  color: #808191;
}

.hour,
.minutes,
.seconds {
  font-size: 7rem;
}

.container-day,
.container-hour,
.container-minutes,
.container-seconds {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.countdown-imge img {
  margin-top: 15px;
  width: 60%;
}

.miliSeconds {
  padding-top: 0.5rem;
  font-size: 2rem;
}

.dot-flashing {
  position: relative;
  width: 6px;
  height: 6px;
  border-radius: 5px;
  background-color: #6c5dd3;
  color: #8173e0;
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}

.dot-flashing::before,
.dot-flashing::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -15px;
  width: 6px;
  height: 6px;
  border-radius: 5px;
  background-color: #6c5dd3;
  color: #8173e0;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 15px;
  width: 6px;
  height: 6px;
  border-radius: 5px;
  background-color: #6c5dd3;
  color: #8173e0;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 1s;
}

.dot-flashing.small {
  width: 4px;
  height: 4px;
}

.dot-flashing.small::before {
  width: 4px;
  height: 4px;
}

.dot-flashing.small::after {
  width: 4px;
  height: 4px;
}

.crash_fallback_wrapper {
  position: relative;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  font-family: 'Poppins', sans-serif;
}
.crash_fallback_wrapper .us_text {
  font-size: 16px;
  max-width: 700px;
  color: #808191;
  padding: 5px;
  text-align: center;
}

.crash_fallback_wrapper .oops {
  color: #808191;
  font-size: 2.5em;
  letter-spacing: 0.05em;
  margin-top: 30px;
  text-align: center;
}
.crash_fallback_wrapper .info {
  color: #808191;
  padding: 5px;
  text-align: center;
}

.crash_fallback_wrapper .button {
  background: #6c5dd3;
  color: #fff;
  text-transform: uppercase;
  padding: 15px 40px;
  border-radius: 50px;
  transition: 150ms;
  font-family: 'Inter';
}
.crash_fallback_wrapper .button:hover {
  background: #7d6fd8;
  transition: 150ms;
}

.crash_fallback_wrapper .button i {
  font-size: 1.2em;
  margin-right: 15px;
}
.crash_fallback_wrapper img {
  padding: 10px;
}

@keyframes dotFlashing {
  0% {
    background-color: #6c5dd3;
  }
  50%,
  100% {
    background-color: #ebe6ff;
  }
}

@media only screen and (max-width: 700px) {
  .hour,
  .minutes,
  .seconds {
    font-size: 3rem;
  }
  .countdown {
    padding: 40px;
  }
  .countdown .info {
    font-size: 15px;
    text-align: center;
    margin-top: 0px;
  }
}
