.dark .referral__code .balances__cell,
.dark .referral__code .balances__price {
  color: white;
}

.referral_col-2 .h6 {
  font-size: 16px;
  text-align: center;
}

.referral-col-2-container {
  margin-top: 20px;
  display: grid;
}

.referral-col-2-container button {
  margin-top: 40px;
}

.img_friends {
  margin: 20px;
}

.download__text {
  text-align: center;
  margin-top: 20px;
  color: #808191;
  font-size: 12px;
  font-family: 'Poppins', sans-serif;
}

.referral__popup .popup_settings {
  max-width: 450px;
}

.referral__popup_container {
  text-align: center;
  margin: 35px 10px;
}

.referral__popup_container .p {
  margin-top: 12px;
  color: #808191;
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
}

.instructions__link {
  color: #8173e0;
  text-decoration: none;
}

.referral__card .promotions__item img {
  width: 130%;
}

.referral__card .promotions__item {
  background-position: center;
  height: 200px;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  border-radius: 15px;
}

.referral__card .promotions__item.blue {
  background-color: #4262f1;
}

.promotions__item .p {
  font-family: 'Poppins', sans-serif;
  font-size: 15px;
  margin-bottom: 10px;
}

.promotions__item.unredeemed {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.promotions__item.unredeemed img {
  width: 80px;
  margin: 18px;
}
.scratch-card-popup {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 40px;
}

.scratch-card-popup img {
  width: 320px;
}

.scratch-card-popup .h5 {
  margin-bottom: 10px;
}

.scratch-card-popup .h6 span {
  color: #8173e0;
}

.referral_share > * {
  margin-right: 12px;
}

.referral__share_popup .popup_settings {
  padding: 0px 32px;
}

.bg-transparent {
  background: transparent !important;
  box-shadow: none;
}

.no__scratch_card {
  margin-left: 40%;
  margin-top: 50px;
}

.no__scratch_card img {
  margin-left: -52px;
  margin-bottom: 42px;
  width: 300px;
}

.referral_loader {
  display: grid;
  place-items: center;
  margin-left: 60;
}

/* .referral_loader .lds-ring {
  width: 80px;
  height: 80px;
}

.referral_loader .lds-ring div {
  width: 50px;
  height: 50px;
} */

.referrals_summary_list {
  margin-top: 20px;
}

.referrals_summary_list li {
  margin-top: 5px;
  list-style: circle;
  margin-left: 15px;
}

.referral_summary_title {
  /* border-bottom: 2px solid #6c5dd3; */
  text-align: center;
  width: fit-content;
  color: #8173e0;
  font-size: 20px;
}

.referral__quotes_container_inner .icon {
  width: 12px;
  margin-left: 3px;
  margin-top: -1px;
  cursor: pointer;
}

.referral_count_remaining {
  font-size: 12px;
  /* border: 1px dashed white; */
  width: fit-content;
  padding: 10px 0px;
  border-radius: 20px;
  /* right: 25px; */
  font-family: 'Poppins', sans-serif;
}

.popup__invite img {
  position: absolute;
  width: 150px;
  top: 20px;
  right: 50px;
}

.popup__invite .popup__wrap {
  width: fit-content;
}

.popup__info.h6 {
  max-width: 400px;
}

.referral_code_connect {
  color: #fff;
  background-color: #6c5dd3;
}
/*
.dark .referral_code_connect {
  background-color: #fff;
  color: #8173E0;
} */

.referral__quotes_container {
  font-family: 'Poppins', sans-serif;
  margin-bottom: 20px;
}

.referral__quotes_container_inner {
  display: flex;
  justify-content: space-between;
  min-width: 310px;
  align-items: center;
  margin-bottom: 5px;
}

.referral__quotes_container_inner h6 {
  font-size: 13px;
  font-weight: normal;
}

.campaigns__details_mask {
  font-size: 19px;
  margin-top: 12px;
  text-transform: uppercase;
  font-family: 'Poppins', sans-serif;
  color: #ba77f7;
}

.external_campaigns {
  background-color: #fff;
}

.dark .external_campaigns {
  background-color: #242731;
}

.redeem__code:disabled {
  opacity: 0.7;
}
