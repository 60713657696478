.notifications__tags {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 24px;
}

.notifications__tags .notifications__link {
  padding: 0 16px;
  border-radius: 20px;
  line-height: 40px;
  font-weight: 600;
  color: #808191;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.notifications__tags .notifications__link:hover {
  color: #11142d;
}

.notifications__tags .notifications__link.active {
  background: rgba(228, 228, 228, 0.3);
  color: #8173e0;
}

.notifications__sorting {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 32px;
}

.notifications__select {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 244px;
  margin-right: 24px;
}

.notifications__search {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.notifications__input {
  width: 100%;
  height: 64px;
  padding: 0 64px 0 32px;
  background: rgba(228, 228, 228, 0.3);
  border-radius: 16px;
  border: none;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #11142d;
}

.notifications__input::-webkit-input-placeholder {
  color: #808191;
}

.notifications__input::-moz-placeholder {
  color: #808191;
}

.notifications__input::-ms-input-placeholder {
  color: #808191;
}

.notifications__input::placeholder {
  color: #808191;
}

.notifications__start {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 64px;
  font-size: 0;
}

.notifications__start .icon {
  font-size: 20px;
  width: 18px;
  fill: #11142d;
  -webkit-transition: fill 0.2s;
  -o-transition: fill 0.2s;
  transition: fill 0.2s;
}

.notifications__start:hover .icon {
  fill: #6c5dd3;
}

.notifications__container {
  padding: 0 16px 40px;
  background: #ffffff;
  border-radius: 20px;
  -webkit-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.07);
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.07);
}

.notifications__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 32px 16px;
  border-radius: 16px;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.notifications__popup {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 32px 16px;
  border-radius: 16px;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.notifications__item:hover {
  background: rgba(228, 228, 228, 0.25);
}

.notifications__item:hover .dropdown {
  opacity: 1;
  visibility: visible;
  z-index: 100;
}

.notifications__item.disabled {
  pointer-events: none;
}

.notifications__icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 48px;
  height: 48px;
  margin-right: 24px;
  border-radius: 50%;
}

.notifications__item.disabled .notifications__icon {
  background: #e4e4e4;
}

.notifications__details {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.notifications__head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 16px;
}

.notifications__title {
  max-width: 280px;
  margin-right: auto;
  font-size: 16px;
  line-height: 1.25;
  font-weight: 600;
  cursor: pointer;
}

.notifications__title:hover {
  text-decoration: underline;
  text-decoration-color: #808191;
}

.notifications__item.disabled .notifications__title {
  color: #808191;
}

.notifications__time {
  position: relative;
  top: -2px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin-left: 40px;
  font-weight: 600;
  color: #808191;
}

.notifications__body {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.notifications__text {
  max-height: 38px;
  overflow: hidden;
  margin-right: auto;
  font-size: 13px;
  line-height: 1.53846;
  color: #808191;
}

.notifications__item.disabled .notifications__text {
  opacity: 0.5;
}

.notifications__status {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 12px;
  height: 12px;
  margin-left: 40px;
  border-radius: 50%;
}

.notifications__item.disabled .notifications__status {
  background: #e4e4e4;
}

.notifications .dropdown {
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.notifications .dropdown__head {
  width: 40px;
  height: 40px;
  -webkit-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.15);
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.15);
}

.notifications .dropdown__head .icon {
  font-size: 16px;
}

.dark .notifications__tags .notifications__link:hover {
  color: #ffffff;
}

.dark .notifications__tags .notifications__link.active {
  background: #191b20;
  color: #8173e0;
}

.dark .notifications__input {
  background: #191b20;
  color: #ffffff;
}

.dark .notifications__start .icon {
  fill: #ffffff;
}

.dark .notifications__start:hover .icon {
  fill: #6c5dd3;
}

.dark .notifications__container {
  background: #242731;
}

.dark .notifications__item:hover {
  background: rgba(228, 228, 228, 0.025);
}

.dark .notifications__item.disabled .notifications__icon {
  background: rgba(228, 228, 228, 0.1);
}

.dark .withdrawal_toast_body {
  background: #242731 !important;
  color: #ffff !important;
}

.withdrawal_toast_body {
  background: #ffffff !important;
  color: #808191 !important;
}

.withdrawal_toast_body .Toastify__close-button svg {
  fill: black !important;
}

.dark .withdrawal_toast_body .Toastify__close-button svg {
  fill: #ffff !important;
}
