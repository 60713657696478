.navbar__ripple_wrapper {
  position: absolute;
  top: 53%;
  transform: translateY(-50%);
  right: 0;
}

.ripple__effect .circles {
  height: 25px;
  position: relative;
  width: 25px;
}
.ripple__effect .circles > div {
  animation: growAndFade 3s infinite ease-out;
  background-color: #6c5dd3;
  border-radius: 50%;
  height: 100%;
  opacity: 0;
  position: absolute;
  width: 100%;
}
.ripple__effect .circles .circle1 {
  animation-delay: 1s;
}

.ripple__effect .circles .circle2 {
  animation-delay: 2s;
}

.ripple__effect .circles .circle3 {
  animation-delay: 3s;
}

@keyframes growAndFade {
  0% {
    opacity: 0.65;
    transform: scale(0);
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
}
