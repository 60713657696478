.payment-status {
  display: grid;
  place-items: center;
}

.payment-status span {
  color: #8173e0;
}

.payment-status p {
  max-width: 520px;
  text-align: center;
  padding: 10px 10px 20px 10px;
  font-family: 'Poppins', sans-serif;
}
.payment-status img {
  max-width: 520px;
}

.payment-status .h2 {
  max-width: 620px;
  text-align: center;
  padding: 30px 0px 0px 0px;
}

.payment-status .sm-text {
  font-size: 10px;
}

.payment-status button {
  margin-bottom: 30px;
}
