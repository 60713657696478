.news__card {
  box-shadow: 0 8px 25px rgb(0 0 0 / 7%);
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 19px;
}

.dark .news__card {
  background: #242731;
}

.news_title {
  font-size: 13px;
  margin-bottom: 2px;
}

.new__image {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 60px;
  height: 60px;
  margin-right: 15px;
}

.news_description {
  font-size: 11px;
  color: #202020;
  font-family: 'Poppins', sans-serif;
}
.dark .news_description {
  font-size: 11px;
  color: whitesmoke;
}
