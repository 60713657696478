.exchange__top {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 48px;
  border-radius: 16px;
  border: 1px solid #e4e4e4;
  background: #ffffff;
}

.header__indicator.market__filter {
  background: rgba(228, 228, 228, 0.4);
  color: #000;
  margin-right: 19px;
  /* width: 94px; */
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.header__indicator.market__filter .header__img {
  background-color: #6c5dd3;
  box-shadow: none;
}

.dark .header__indicator.market__filter {
  background: rgba(51, 53, 58, 1);
  color: #ffff;
}

.header__indicator.market__filter:hover .header__img {
  animation: none;
}

.exchange__top:after {
  content: '';
  position: absolute;
  bottom: -17px;
  left: 0;
  right: 0;
  z-index: -1;
  height: 38px;
  border-radius: 16px;
  background: #e4e4e4;
  -webkit-filter: blur(86.985px);
  filter: blur(86.985px);
}

.exchange__cell {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding: 35px 24px;
  border-right: 1px solid #e4e4e4;
}

.exchange__cell:last-child {
  border: none;
}

.exchange__company {
  position: relative;
  z-index: 1;
}

.exchange__head {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-right: 20px;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.exchange__logo {
  margin-right: 16px;
}

.exchange__logo img {
  width: 100%;
  min-height: 100%;
}

.exchange__head .exchange__logo {
  width: 40px;
  height: 40px;
}

.exchange__currency {
  font-weight: 600;
}

.title-bcHj6pEn.title3rd-2bpagZ7H.apply-overflow-tooltip.withDot-VrJCTK-- {
  display: none;
}

.exchange__info {
  font-size: 12px;
  line-height: 1.33333;
  font-weight: 600;
  color: #808191;
  font-family: 'Poppins';
}

.exchange__arrow {
  position: absolute;
  top: 8px;
  right: 0;
  font-size: 0;
  -webkit-transition: -webkit-transform 0.2s;
  transition: -webkit-transform 0.2s;
  -o-transition: transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
}

.exchange__arrow .icon {
  font-size: 7px;
  width: 15px;
}

.exchange__company.active .exchange__arrow {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.exchange__details {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.exchange__body {
  position: absolute;
  top: calc(100% + 16px);
  left: -24px;
  right: -24px;
  padding: 12px 8px;
  background: #ffffff;
  border-radius: 20px;
  -webkit-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.07);
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.07);
  visibility: hidden;
  opacity: 0;
  /* -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s; */
}

.exchange__company.active .exchange__body {
  visibility: visible;
  opacity: 1;
  max-height: 200px;
  overflow-y: scroll;
}

.exchange__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 10px 18px;
  border-radius: 12px;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.exchange__item:hover {
  background: rgba(228, 228, 228, 0.3);
}

.exchange__item:not(:last-child) {
  margin-bottom: 4px;
}

.exchange__item .exchange__logo {
  width: 36px;
  height: 36px;
}

.exchange__item .exchange__currency {
  color: #11142d;
}

.exchange .status {
  margin-top: 5px;
}

.exchange__price {
  font-weight: 600;
  font-size: 13px;
}

.exchange__price.last_price {
  font-size: 16px;
}

.exchange__row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.exchange__container {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  padding-right: 64px;
}

.exchange__sidebar {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 340px;
}

.exchange .orders:not(:last-child) {
  margin-bottom: 48px;
}

.exchange .actions:not(:last-child) {
  margin-bottom: 56px;
}

.exchange__banner {
  position: relative;
  min-height: 335px;
  padding: 32px;
  border-radius: 24px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  color: #ffffff;
}

.exchange__banner:before {
  content: '';
  position: absolute;
  top: 8px;
  left: 8px;
  right: 8px;
  bottom: -8px;
  z-index: -1;
  border-radius: 24px;
  background: rgba(108, 93, 211, 0.5);
}

.exchange__date {
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.exchange__title {
  margin-bottom: 16px;
}

.exchange__banner .exchange__btn {
  -webkit-box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.200219);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.200219);
}

.exchange__foot {
  display: none;
  margin-top: auto;
  padding-top: 32px;
}

.exchange__menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 -16px;
  padding: 20px 16px 42px;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  border: 1px solid #e4e4e4;
  border-radius: 20px 20px 0 0;
}

.exchange__menu .exchange__link {
  position: relative;
  font-size: 0;
}

.exchange__menu .exchange__link .icon {
  font-size: 24px;
  fill: #808191;
  width: 15px;
  -webkit-transition: fill 0.2s;
  -o-transition: fill 0.2s;
  transition: fill 0.2s;
}

.exchange__menu .exchange__link i {
  font-size: 24px;
  color: #808191;
  -webkit-transition: fill 0.2s;
  -o-transition: fill 0.2s;
  transition: fill 0.2s;
}

.exchange__menu .exchange__link:before {
  content: '';
  position: absolute;
  top: calc(100% + 30px);
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #6c5dd3;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.connect__wallet_chart {
  display: flex;
  padding: 38px;
  border-radius: 30px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(228, 228, 228, 0.9);
  z-index: 10;
}

.static__content_container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  /* grid-column-gap: 10px;
  grid-row-gap: 10px; */
}

.static__content_container img {
  width: 30px;
  margin-right: 15px;
}

.static__content_container p {
  text-align: center;
  max-width: 250px;
  font-family: 'Poppins', sans-serif;
  color: #808191;
  font-size: 12px;
}

.static__content_item.border-content {
  border: 1px solid #e4e4e4;
}

.dark .static__content_item.border-content {
  border: 1px solid rgba(228, 228, 228, 0.1);
}
.static__content_item {
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.static__content_item .title-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 12px;
  font-size: 15px;
}

.absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.connect__wallet_chart p {
  max-width: 220px;
  text-align: center;
  font-family: 'Poppins';
}

.dark .chart_bg {
  /* background: url('../../assests/img/trade-view.png') no-repeat center center; */
  background: url('../../assests/img/trade-view.png') no-repeat 0px 1px / cover;
  filter: blur(8px);
  -webkit-filter: blur(8px);
}

.chart_bg {
  /* background: url('../../assests/img/trade-view.png') no-repeat center center; */
  background: url('../../assests/img/trade-view-white.png') no-repeat 0px 1px /
    cover;
  filter: blur(8px);
  -webkit-filter: blur(8px);
}

.dark .connect__wallet_chart {
  background-color: #191b20;
}

.connect__wallet_chart .btn {
  margin-top: 20px;
}

.exchange__menu .exchange__link p {
  font-family: 'Poppins', sans-serif;
}
.exchange__menu .exchange__link.note:before {
  visibility: visible;
  opacity: 1;
}

.exchange__menu .exchange__link.active .icon {
  fill: #6c5dd3;
}

.exchange__menu .exchange__link.active .icon {
  color: #8173e0;
}

.dark .market__item {
  background: #141623;
  border-radius: 30px 30px 0px 0px;
}

.dark .exchange__top {
  background: #242731;
  border-color: rgba(228, 228, 228, 0.1);
}

.dark .exchange__top:after {
  background: rgba(228, 228, 228, 0.1);
}

.dark .exchange__cell {
  border-color: rgba(228, 228, 228, 0.1);
}

.dark .exchange__arrow .icon {
  fill: #ffffff;
}

.dark .exchange__body {
  background: #191b20;
  border: 1px solid rgba(228, 228, 228, 0.1);
}

.dark .exchange__item:hover {
  background: #242731;
}

.dark .exchange__item .exchange__currency {
  color: #ffffff;
}

.dark .exchange__menu {
  border-color: rgba(228, 228, 228, 0.1);
}

.market {
  border-radius: 32px;
  border: 1px solid #e4e4e4;
}

.market__tabs {
  border-bottom: 1px solid #e4e4e4;
}

.market__head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 24px;
  border-bottom: 1px solid #e4e4e4;
}

.market__menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-right: auto;
}

.market__menu .market__link {
  padding: 0 16px;
  border-radius: 20px;
  line-height: 40px;
  font-weight: 600;
  color: #808191;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.market__menu .market__link:hover {
  color: #11142d;
}

.market__menu .market__link.active {
  background: rgba(228, 228, 228, 0.3);
  color: #8173e0;
}

.market__actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.market__action {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: 600;
  color: #11142d;
  -webkit-transition: color 0.2s;
  -o-transition: color 0.2s;
  transition: color 0.2s;
}

.market__action .icon {
  font-size: 16px;
  width: 15px;
  fill: #11142d;
  -webkit-transition: fill 0.2s;
  -o-transition: fill 0.2s;
  transition: fill 0.2s;
}

.market__action .icon:not(:last-child) {
  margin-right: 8px;
}

.market__action:hover {
  color: #8173e0;
}

.market__action:hover .icon {
  fill: #6c5dd3;
}

.market__action:not(:last-child) {
  margin-right: 32px;
}

.market__item {
  display: none;
  padding: 24px;
}

.market__legend {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: -12px 0 0 -24px;
}

.market__variant {
  margin: 12px 0 0 24px;
  font-size: 15px;
  line-height: 1.33333;
  font-weight: 500;
  color: #808191;
}

.market__chart {
  height: 400px;
}

.market__chart_price {
  margin-top: 10px;
  margin-left: -12px;
}

.market__chart_deep {
  margin-top: 20px;
  margin-right: -10px;
  margin-left: -30px;
  margin-bottom: 20px;
}

.market__top {
  padding: 24px;
}

.market__top .market__menu {
  /* margin-bottom: 24px; */
}

.market__table {
  display: table;
  width: 100%;
}

.market__row {
  display: table-row;
}

.market__cell {
  display: table-cell;
  height: 40px;
  padding-left: 24px;
  vertical-align: middle;
  border-top: 1px solid #e4e4e4;
  text-align: center;
}

.market__cell:last-child {
  padding-right: 24px;
}

.market__row_head .market__cell {
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #808191;
}

.market__row:not(.merket__row_head) .market__cell {
  font-size: 12px;
  line-height: 1.33333;
  font-weight: 500;
}

.dark .market {
  border-color: rgba(228, 228, 228, 0.1);
}

.dark .market__head {
  border-color: rgba(228, 228, 228, 0.1);
}

.dark .market__menu .market__link:hover {
  color: #ffffff;
}

.dark .market__menu .market__link.active {
  background: #191b20;
  color: #8173e0;
}

.dark .market__action {
  color: #ffffff;
}

.dark .market__action .icon {
  fill: #ffffff;
}

.dark .market__action:hover {
  color: #8173e0;
}

.dark .market__action:hover .icon {
  fill: #6c5dd3;
}

.dark .market__tabs,
.dark .market__cell {
  border-color: rgba(228, 228, 228, 0.1);
}

.dark .market__chart .apexcharts-xaxis line,
.dark .market__chart .apexcharts-grid line {
  stroke: rgba(228, 228, 228, 0.1);
}

.orders__head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 24px;
}

.orders__title {
  margin-right: auto;
}

.orders__sorting {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.orders__link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  padding: 8px;
  -webkit-transition: -webkit-box-shadow 0.2s;
  transition: -webkit-box-shadow 0.2s;
  -o-transition: box-shadow 0.2s;
  transition: box-shadow 0.2s;
  transition: box-shadow 0.2s, -webkit-box-shadow 0.2s;
}

.orders__link span {
  width: 100%;
  height: 2px;
  border-radius: 1;
}

.orders__link span:last-child {
  width: 50%;
}

.orders__link span:not(:last-child) {
  margin-bottom: 3px;
}

.orders__link.active {
  -webkit-box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.orders__link:not(:last-child) {
  margin-right: 24px;
}

.orders__table {
  border-radius: 12px;
  border: 1px solid #e4e4e4;
  overflow: hidden;
}

.orders__row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}

.orders__row_head {
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #808191;
}

.orders__row:not(.orders__row_head) {
  font-size: 12px;
  line-height: 1.33333;
  font-weight: 500;
}

.orders__cell {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  height: 32px;
  padding-right: 12px;
}

.orders__cell:first-child {
  position: relative;
  padding-left: 12px;
}

.orders__cell:not(:first-child) {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.orders__row_head .orders__cell {
  height: 24px;
}

.orders__price {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 40px;
  border-width: 1px 0;
  border-style: solid;
  border-color: #e4e4e4;
  font-size: 16px;
  line-height: 1.25;
  font-weight: 600;
}

.orders__bg {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
}

.orders__up {
  color: #ff7a68;
}

.orders__up .orders__bg {
  background: rgba(255, 122, 104, 0.3);
}

.orders__down {
  color: #3dbaa2;
}

.orders__down .orders__bg {
  background: rgba(61, 186, 162, 0.3);
}

.orders__bg_green {
  background: rgba(61, 186, 162, 0.3);
}

.orders__bg_red {
  background: rgba(255, 122, 104, 0.3);
}

.dark .orders__link.active {
  background: #191b20;
}

.dark .orders__table {
  background: #242731;
  border-color: rgba(228, 228, 228, 0.1);
}

.dark .orders__price {
  border-color: rgba(228, 228, 228, 0.1);
}

.actions {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 30px 24px 15px;
  border-radius: 24px;
  background: #ffffff;
}

.actions:before {
  content: '';
  position: absolute;
  top: 8px;
  left: 12px;
  right: 12px;
  bottom: -8px;
  z-index: -1;
  border-radius: 24px;
  background: #e4e4e4;
  -webkit-filter: blur(86.985px);
  filter: blur(86.985px);
}

.actions__menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 -4px 20px;
}

.actions__link {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin: 0 4px;
  border-radius: 20px;
  text-align: center;
  line-height: 40px;
  font-weight: 600;
  color: #808191;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.actions__link:hover {
  color: #11142d;
}

.actions__link.active {
  background: rgba(228, 228, 228, 0.3);
  color: #8173e0;
}

.actions__fieldset {
  margin-bottom: 20px;
}

.actions__field {
  position: relative;
}

.actions__field:not(:last-child) {
  margin-bottom: 12px;
}

.actions__label {
  position: absolute;
  top: 12px;
  left: 24px;
  /* z-index: 3; */
  pointer-events: none;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #808191;
}

.actions .select {
  padding-top: 34px;
  padding-left: 24px;
  border-radius: 12px;
  line-height: 1;
}

.actions__input {
  width: 100%;
  height: 64px;
  padding: 26px 80px 10px 24px;
  border-radius: 12px;
  background: rgba(228, 228, 228, 0.3);
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 700;
  color: #11142d;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.actions__input:focus {
  background: #ffffff;
  -webkit-box-shadow: 0 0 0 2px #6c5dd3;
  box-shadow: 0 0 0 2px #6c5dd3;
}
.actions__input.active {
  background: #ffffff;
  -webkit-box-shadow: 0 0 0 2px #6c5dd3;
  box-shadow: 0 0 0 2px #6c5dd3;
}

.actions__input.active_gray {
  background: #ffffff;
  -webkit-box-shadow: 0 0 0 1px #c5c5c7;
  box-shadow: 0 0 0 1px #c5c5c7;
}

.dark .actions__input.active_gray {
  box-shadow: 0 0 0 1px #808191;
}

.actions__currency {
  position: absolute;
  top: 28px;
  right: 24px;
  pointer-events: none;
  text-align: right;
  font-weight: 700;
}

.dark .actions {
  background: #242731;
}

.dark .actions:before {
  display: none;
}

.dark .actions__link:hover {
  color: #ffffff;
}

.dark .actions__link.active {
  background: #191b20;
  color: #8173e0;
}

.dark .actions__input {
  background: #191b20;
  color: #ffffff;
}

.dark .actions__input:focus {
  background: none;
}

.dark .actions .select {
  background: #191b20;
}

.dark .actions .select.open {
  background: none;
}

.trade_view__chart {
  width: 100%;
  height: 400px;
  border-radius: 20px;
}

.TVChartContainer {
  width: 100% !important;
  height: 100%;
}

.exchange_chart_order {
  border: none;
  border-radius: 0px;
  width: 100%;
  border-bottom: 1px solid #e4e4e4;
}

.exchange_chart_order .orders__price {
  font-size: 12px;
  text-transform: uppercase;
}

.dark.exchange_chart_order {
  border-bottom: 1px solid rgba(228, 228, 228, 0.1);
}

.exchange__order-book {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.exchange__order-book > *:not(:first-child) {
  border-left: 1px solid #e4e4e4;
}

.dark .exchange__order-book > *:not(:first-child) {
  border-left: 1px solid rgba(228, 228, 228, 0.1);
}

.exchange_search__input {
  margin-left: 6px;
  padding: 23px 20px 23px 24px;
  height: 20px;
  margin-bottom: 12px;
}

.numbers_slider {
  border-radius: 24px;
  background: rgba(228, 228, 228, 0.3);
  margin-right: 10;
  font-size: 11px;
  margin-left: 12px;
  padding: 10px;
  cursor: pointer;
  transition: all 0.2s;
  color: #808191;
  margin-top: 20px;
}

.dark .numbers_slider {
  background-color: #191b20;
}

.numbers_slider:hover {
  box-shadow: 0 0 2px;
  transform: scale(1.1);
}

.exchange_toggle_mode {
  font-family: 'Poppins', sans-serif;
}

.exchange_toggle_mode p {
  font-size: 10px;
  color: #808191;
  max-width: 200px;
  margin-right: 20px;
}

.exchange_toggle_mode h5 {
  font-size: 13px;
  max-width: 180px;
}

.toggle_mode {
  justify-content: space-between;
  align-items: center;
  /* margin: 0 4px -7px; */
  background-color: rgba(108, 93, 211, 0.1);
  border: 1px #6c5dd3 solid;
  padding: 1px 20px 1px 20px;
  border-radius: 10px;
}

.toggle_mode .custom_checkbox {
  margin-bottom: 26px !important;
}

.toggle_mode .custom_checkbox input[type='checkbox'] {
  width: 35px;
  height: 21px;
}

.toggle_mode .custom_checkbox input[type='checkbox']::before {
  width: 20px;
  height: 20px;
}

.toggle_mode .custom_checkbox input[type='checkbox']:checked::before {
  left: 15px;
}

.toggle_mode .custom_checkbox input[type='checkbox']:checked {
  background-color: #6c5dd3;
}

.change_trade_mode i {
  font-size: 20px;
  margin-top: 9px;
  color: #8173e0;
  margin-right: 10;
  cursor: pointer;
  border: 1px #6c5dd3 solid;
  padding: 10px;
  border-radius: 50%;
  transition: all 0.2s;
}

.change_trade_mode i:hover {
  transform: scale(1.1);
}

.exchange_invested_data .details__box {
  border: none !important;
}

.exchange_list.details__list {
  justify-content: space-evenly;
}

.exchange_list .details__box {
  background-color: rgba(228, 228, 228, 0.3);
  margin-bottom: 20px;
  border-radius: 10px;
  border: none;
  width: 290px !important;
}

.dark .exchange_list .details__box {
  background-color: #191b20;
}

.exchange_chart_beginner .details__top {
  margin-bottom: 0px;
}

/* .dark .details__container.exchange_chart_beginner {
  background-color: #1f2128;
} */

.refresh__balance {
  position: absolute;
  top: 45px;
  right: 40px;
  cursor: pointer;
}

.refresh__balance i,
.refresh__balance__wallet i {
  font-size: 17px;
  border: 1px #6c5dd3 solid;
  padding: 5px;
  border-radius: 50%;
  color: #8173e0;
}

.refresh__balance__wallet {
  margin-left: 10px;
}

.refresh__balance.disable_btn i {
  opacity: 0.3;
}
.refresh__balance__wallet.disable_btn i {
  opacity: 0.3;
}
.pro_mode_invested_container {
  display: flex;
  justify-content: center;
}

.pro_mode_invested_item {
  background-color: rgba(228, 228, 228, 0.3);
  min-width: 160px;
  border-radius: 20px;
  text-align: center;
  padding: 20px;
  font-family: 'Poppins', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.pro_mode_invested_item h5 {
  margin-top: 6px;
}
.pro_mode_invested_item h3 {
  font-weight: 500;
  font-size: 15px;
}
.dark .pro_mode_invested_item {
  background-color: #191b20;
}

.pro_mode_invested_image {
  padding: 0px;
  border: 1px #6c5dd3 solid;
  border-radius: 50%;
  width: fit-content;
  padding: 8px 10px;
}

.pro_mode_invested_item img {
  width: 20px;
}

.pro_mode_invested_item h3 {
  margin-top: 10px;
}

.pro_mode_portfolio_container {
  margin-bottom: 30px;
  /* margin-top: -30px; */
  padding: 10px;
}

.color-purple {
  color: #8b3fc1;
}

.dark .color-purple {
  color: #c18de7;
}
.pro_mode_exchange {
  max-height: 100vh;
}

.pro_mode_exchange .exchange__top {
  border-radius: 0px;
}

.pro_mode_exchange .header__group {
  justify-content: center;
}

.pro_mode_exchange .exchange__cell {
  padding: 18px 24px;
}

.pro_mode_exchange .exchange__top {
  margin-bottom: 0px;
}

.pro_mode_exchange .actions {
  border-radius: 0px;
  padding: 10px 24px 15px;
  width: 100%;
  height: fit-content;
  margin-bottom: 9px;
}

.pro_mode_exchange .exchange__container {
  flex-grow: 0.5;
}
.pro_mode_exchange .pro_exchange_action {
  display: flex;
  flex-direction: column;
}

.pro_mode_exchange .pro_exchange_action.pro_reverse {
  flex-direction: column-reverse;
}

.dark .pro_mode_exchange .pro_exchange_action {
  background-color: #242731;
  display: flex;
  flex-direction: column;
}

.pro_mode_exchange .pro_exchange_action {
  background-color: #ffff;
  height: calc(100vh - 86px);
  overflow-y: scroll;
  width: 100%;
}

.pro_mode_exchange .actions:before {
  content: none;
}

.pro_mode_exchange .exchange__sidebar {
  flex-grow: 0.5;
  width: 55%;
}
.pro_mode_exchange .exchange__container {
  padding-right: 0px;
}
.pro_mode_exchange .market__chart {
  height: 45vh;
}
.pro_mode_exchange .orders {
  width: 280px;
  height: 100vh;
  border-radius: 0px;
}

.pro_mode_exchange .orders__table {
  border-radius: 0px;
  /* height: 100vh; */
  border-right: none;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 130px);
}

.pro_mode_exchange .market__item {
  border-radius: 0px;
}
.pro_mode_exchange .market__top {
  padding: 10px;
}

.pro_mode_exchange .exchange__row {
  display: flex;
  flex-direction: row;
}

.pro_mode_exchange .exchange__row.pro_reverse {
  flex-direction: row-reverse;
}

.pro_mode_exchange .orders__head {
  margin: 1px 0px;
  display: grid;
  padding: 2px;
  place-items: center;
  border-left: 1px #e4e4e4 solid;
}
.dark .pro_mode_exchange .orders__head {
  border-left: 1px solid rgba(228, 228, 228, 0.1);
}
.pro_mode_exchange .orders__title {
  margin-right: revert;
  font-size: 10px;
  font-weight: 700;
  line-height: 1.6;
  letter-spacing: 0.9px;
  text-transform: uppercase;
  color: #808191;
  font-family: 'Inter' !important;
}

.pro_mode_exchange .orders__head .h6:hover {
  color: #3dbaa2;
}

.dark .pro_mode_exchange .orders__title:hover {
  color: #fff;
}

.pro_mode_exchange .orders__title:hover {
  color: #000;
}

.pro_mode_exchange .orders__head .h6 {
  font-size: 11px;
  text-align: center;
}

.pro_mode_exchange .pro_item {
  flex: 1;
}

.pro_mode_exchange .pro_item .order_book {
  flex: 0.5 !important;
}

.pro_mode_exchange .actions__menu {
  margin: 0px;
}

.pro_mode_exchange .table_height_control {
  max-height: 30vh;
  overflow-y: scroll;
  overflow-x: hidden;
}
.pro_mode_exchange .table_height_control.order_table {
  /* height: 37vh !important; */
  /* max-height: max-content; */
  max-height: 100%;
  flex: 1;
  overflow-y: scroll;
}

.pro_mode_exchange .table_height_control.all_trade {
  height: 100vh !important;
}

.pro_mode_exchange .profit_container {
  background-color: rgba(228, 228, 228, 0.3);
  height: 18vh;
  border-radius: 20px;
  margin: 13px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.pro_mode_exchange .pro_exchange_action .actions {
  margin-bottom: 20px !important;
  margin-top: 10px !important;
}

.pro_mode_exchange .pro_exchange_action.pro_reverse {
  flex-direction: column-reverse;
  justify-content: flex-end;
  height: calc(100vh - 86px);
  overflow-y: scroll;
  width: 100%;
}

.dark .pro_mode_exchange .profit_container {
  background-color: #191b20;
}
.pro_mode_exchange .market {
  border-radius: 0px;
}

.pro_mode_exchange .profit_container {
  font-family: 'Poppins';
  padding: 10px;
  position: relative;
}

.pro_mode_exchange .profit_container h6 {
  color: #808191;
  font-size: 12px;
}

.pro_mode_exchange .profit_container p {
  font-weight: 600;
}

.pro_mode_exchange .profit_container p {
  font-weight: 600;
}

.pro_mode_exchange .profit_container .profit {
  text-align: right;
  position: absolute;
  bottom: 10px;
  right: 10px;
}
.pro_mode_exchange .profit_container .profit p {
  font-size: 18px;
}
.pro_mode_exchange {
  height: 100vh;
}

.pro_mode_exchange .exchange_search__input {
  height: 20px !important;
}

.pro_mode_exchange .operations__counter {
  font-size: 12px;
}

.pro_mode_exchange .orders__row_head {
  /* font-size: 9px;
  padding: 4px; */

  font-size: 9px;
  font-weight: 700;
  line-height: 1.6;
  letter-spacing: 0.9px;
  text-transform: uppercase;
  color: #808191;
  padding: 4px;
  font-family: 'Inter' !important;
}

.pro_mode_exchange .orders__table,
.dark .pro_mode_exchange .order_table {
  background: transparent;
}

.pro_mode_exchange .exchange__top,
.dark .pro_mode_exchange .exchange__top {
  background: transparent;
}

.pro_mode_exchange .market {
  height: 100vh;
}
.pro_mode_exchange .operations__balance {
  padding: 15px 24px;
}

.pro_mode_exchange .numbers_slider {
  margin-top: 0px;
}

/* .pro_mode_exchange .order_book_custom_select .css-yk16xz-control,
.pro_mode_exchange .order_book_custom_select .css-1pahdxg-control {
  padding: 9px 20px;
} */

.pro_mode_exchange .actions__input {
  height: 58px;
}

.pro_mode_exchange .actions__currency {
  top: 18px;
}
.pro_mode_exchange .btn {
  height: 39px;
}
.pro_mode_exchange .pro_flex {
  display: flex;
  justify-content: end;
  align-items: start;
}

.pro_mode_exchange .pro_flex.pro_reverse {
  flex-direction: row-reverse;
}

.fullscreen-enabled .pro_mode_exchange {
  background: #fdfdfd;
}

.dark .fullscreen-enabled .pro_mode_exchange {
  background: #1f2128;
}

.orders__row.order__asks:not(:first-child):hover {
  border-top: #808191 1px dashed;
}

.orders__row.order__bids:hover {
  border-bottom: #808191 1px dashed;
  border-top: none;
}

.order_table_asks_container:hover .active {
  opacity: 0.5;
}

.order_table_bids_container:hover .active {
  opacity: 0.5;
}

.market__tabs_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.market__refresh_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.market_tab__refresh {
  position: relative;
  top: 0px;
  margin-right: 20px;
  right: 0px;
  width: fit-content;
}

.chart__loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.chart__loader .lds-default {
  width: 65px;
  height: 65px;
}

.chart__loader .lds-default div {
  background: #808191;
  width: 5px;
  height: 5px;
}

.dark .chart__loader .lds-default div {
  background: #808191;
}

@media only screen and (max-width: 600px) {
  .exchange_list.details__list {
    padding: 10px;
  }
  .exchange_list .details__box {
    width: auto !important;
  }
  .exchange_chart_beginner .details__top {
    margin-bottom: 29px;
  }
  .wallet_dropdown {
    width: 210px;
  }
  .pro_mode_portfolio_container {
    padding: 11px 13px;
    margin-top: 10px;
    margin-bottom: 0px;
  }
  .order_table {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 200px;
  }
  .all_trade {
    height: 400px;
  }
  .market_tab__refresh {
    width: 100%;
  }
  .market__refresh_wrapper {
    margin-bottom: 20px;
    margin-left: 12px;
  }
}
