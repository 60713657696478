.promotions__wrapper {
  padding: 0 64px 64px;
}

.promotions__title {
  margin-bottom: 48px;
}

.promotions__row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 48px;
}

.promotions__col:first-child {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 calc(100% - 330px);
  flex: 0 0 calc(100% - 330px);
  max-width: calc(100% - 330px);
  padding-right: 32px;
}

.promotions__col:nth-child(2) {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 330px;
}

.promotions__head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 32px;
}

.promotions__box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 33px 31px;
  border-radius: 16px;
  border: 1px solid #e4e4e4;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.promotions__box:hover {
  border-color: transparent;
  background: rgba(228, 228, 228, 0.3);
}

.promotions__box:not(:last-child) {
  margin-bottom: 16px;
}

.promotions__group_sm .promotions__box {
  padding: 33px 23px;
}

.promotions__icon {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 72px;
  height: 72px;
  margin-right: 20px;
}

.promotions__icon img {
  width: 100%;
  min-height: 100%;
  border-radius: 50%;
}

.promotions__box .promotions__date {
  margin-bottom: 4px;
  font-size: 12px;
  line-height: 1.33333;
  font-weight: 500;
  color: #808191;
}

.promotions__info {
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  line-height: 1.33333;
  font-weight: 500;
  color: #11142d;
}

.promotions__stage {
  margin-bottom: 32px;
}

.promotions__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: -40px -16px 40px;
}

.promotions__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 calc(33.333% - 32px);
  flex: 0 0 calc(33.333% - 32px);
  width: calc(33.333% - 32px);
  margin: 40px 16px 0;
  padding: 8px;
  border-radius: 20px;
  background: #ffffff;
  -webkit-box-shadow: 0 5px 30px rgba(0, 0, 0, 0.07);
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.07);
}

.promotions__preview img {
  width: 100%;
  border-radius: 20px;
}

.promotions__body {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  padding: 24px 16px 14px;
}

.promotions__item .promotions__date {
  display: inline-block;
  margin-bottom: 8px;
  padding: 0 16px;
  border-radius: 16px;
  font-size: 12px;
  line-height: 32px;
  font-weight: 500;
}

.promotions__item .promotions__info {
  display: block;
  margin-bottom: 16px;
  -webkit-transition: color 0.2s;
  -o-transition: color 0.2s;
  transition: color 0.2s;
}

.promotions__item:hover .promotions__info {
  color: #8173e0;
}

.promotions__transactions {
  margin-bottom: 32px;
}

.promotions__transactions .promotions__category {
  margin-bottom: 16px;
}

.promotions__updated {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 32px;
  font-size: 12px;
  line-height: 1.33333;
  font-weight: 500;
  color: #808191;
}

.promotions__updated .icon {
  margin-right: 10px;
}

.promotions__table {
  margin-bottom: 24px;
}

.promotions__flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 40px;
  border-radius: 8px;
  cursor: pointer;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.promotions__flex_head {
  background: rgba(228, 228, 228, 0.2);
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #808191;
}

.promotions__flex:not(.promotions__flex_head) {
  font-weight: 600;
}

.promotions__flex:not(.promotions__flex_head):hover {
  background: rgba(255, 159, 56, 0.2);
}

.promotions__flex:not(:last-child) {
  margin-bottom: 4px;
}

.promotions__cell:first-child {
  width: 68px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 68px;
  flex: 0 0 68px;
  padding: 0 16px;
  color: #808191;
}

.promotions__cell:nth-child(2) {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 80px;
  flex: 0 0 80px;
  width: 80px;
}

.promotions__cell:nth-child(3) {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  padding: 0 24px 0 12px;
  text-align: right;
}

.promotions__btns {
  text-align: center;
}

.dark .promotions__box {
  background: #242731;
  border-color: rgba(228, 228, 228, 0.1);
}

.dark .promotions__box:hover {
  background: rgba(228, 228, 228, 0.03);
  border-color: transparent;
}

.dark .promotions__info {
  color: #ffffff;
}

.dark .promotions__item {
  background: #242731;
}

.dark .promotions__flex_head {
  background: rgba(228, 228, 228, 0.03);
}

.dark .promotions__flex:not(.promotions__flex_head):hover {
  background: rgba(255, 159, 56, 0.2);
}

.promotion {
  padding: 8px;
  border-radius: 20px;
  background: #ffffff;
  -webkit-box-shadow: 0 8px 30px rgba(0, 0, 0, 0.08);
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.08);
}

.promotion__preview img {
  width: 100%;
  border-radius: 16px;
}

.promotion__body {
  padding: 28px 24px 32px;
}

.promotion__title {
  position: relative;
  margin-bottom: 24px;
  padding-bottom: 26px;
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  line-height: 1.33333;
  font-weight: 500;
}

.promotion__title:before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 64px;
  height: 2px;
  border-radius: 1px;
  background: #6c5dd3;
}

.promotion__text {
  color: #808191;
}

.promotion__text:not(:last-child) {
  margin-bottom: 32px;
}

.promotion__note {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 32px;
  padding: 18px;
  border: 2px solid #6c5dd3;
  border-radius: 8px;
  background: rgba(63, 140, 255, 0.1);
  font-weight: 600;
}

.promotion__icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  margin-right: 20px;
  border-radius: 50%;
  background: #6c5dd3;
}

.promotion__category {
  margin-bottom: 24px;
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  line-height: 1.33333;
  font-weight: 500;
}

.promotion__list {
  margin-bottom: 24px;
  border-radius: 8px;
  border: 1px solid #e4e4e4;
}

.promotion__line {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-height: 56px;
  padding: 16px 32px;
  font-size: 16px;
  font-weight: 600;
}

.promotion__line .icon {
  position: relative;
  bottom: -5px;
  margin-right: 16px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  font-size: 16px;
}

.promotion__line:first-child {
  color: #ff6628;
}

.promotion__line:first-child .icon {
  fill: #ff6628;
}

.promotion__line:not(:last-child) {
  border-bottom: 1px solid #e4e4e4;
}

.dark .promotion {
  background: #242731;
}

.dark .promotion__list {
  border-color: rgba(228, 228, 228, 0.1);
}

.dark .promotion__line {
  border-color: rgba(228, 228, 228, 0.1);
}

.dark .promotion__line .icon {
  fill: #ffffff;
}

.dark .promotion__line:first-child .icon {
  fill: #ff6628;
}
