.tooltip_styles {
  font-family: 'Poppins', sans-serif;
  z-index: 1000;
}

.tooltip_styles.price_details {
  max-width: 210px;
  text-align: center;
  font-size: 11px;
}

.tooltip_styles.withdrawal_instruction {
  max-width: 300px;
  font-size: 11px;
}

.tooltip_styles.feedback__email_details {
  max-width: 270px;
  text-align: center;
  font-size: 11px;
}

.tooltip_styles.order_tooltip {
  max-width: 210px;
  font-size: 11px;
  font-weight: 600;
}

.tooltip_styles.fast_withdrawal {
  max-width: 210px;
  font-size: 11px;
  font-weight: 500;
}

.tooltip_styles.max-withdrawal {
  max-width: 220px;
  font-size: 11px;
  font-weight: 500;
}

.Toastify__toast-container {
  z-index: 5;
}

.tooltip_styles.email_details {
  max-width: 200px;
  text-align: center;
  font-size: 11px;
}
