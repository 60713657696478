.salt__banner-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 20;
}

.salt__banner-img {
  width: '100%';
  height: '100%';
  object-fit: cover;
}

.salt__username {
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.salt__prize img {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.salt__prize {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  background-color: transparent;
  /* height: 200px; */
  padding: 0px;
  width: 150px;
}

.salt__prize img {
  background-color: rgba(228, 228, 228, 0.2);
}

.dark .salt__prize img {
  background-color: #25272e;
}

.salt__prize img {
  height: 147px;
}

.dark .salt__prize {
  background-color: transparent;
}

.salt__prize .salt__rank {
  position: absolute;
  top: -15px;
  right: -25px;
  background-color: rgba(228, 228, 228, 1);
  width: 50px;
  height: 50px;
  border-radius: 25px;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.prize__salt_item {
  padding-top: 15px;
}

.salt__prize:not(:nth-child(1)) {
  margin-left: 27px;
}

.salt__prize .salt__score {
  font-size: 16px;
  margin-top: 7px;
  font-family: 'Poppins';
}

.salt__prize .salt__winner {
  font-size: 13px;
  margin-top: 7px;
  color: #808191;
  font-family: 'Poppins';
}

.dark .salt__prize .salt__rank {
  background-color: #25272e;
}

.brinard__nft {
  /* background-color: rgba(228, 228, 228, 0.3); */
  /* margin-top: 84px; */
}

.brinard__nft::before {
  content: '';
  position: absolute;
  top: 8px;
  left: 8px;
  right: 8px;
  bottom: -8px;
  z-index: -1;
  border-radius: 24px;
  background: rgba(255, 255, 255, 0.5);
}

.brinard__nft .h4 {
  font-size: 16px;
}

.brinard__nft .h6 {
  font-size: 12px;
  color: #808191;
}

.dark .brinard__nft {
  background-color: #25272e;
}

.brinard__nft {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
  border-radius: 10px;
}

.dark .salt__description span {
  color: #fff;
}

.salt__description span {
  color: #000;
}

.salt__title {
  font-size: 14px;
}

.brinard__nft_text {
  padding: 20px 40px;
}

.brinard__nft .nft__list {
  display: flex;
  flex-direction: row;
}

.brinard__nft .nft__list img {
  width: 80px;
  height: 80px;
}

.brinard__nft .nft__list img:not(:first-child) {
  margin-left: 10px;
}

.brinard__nft {
  padding: 0;
  padding-top: 20px;
}

.salt_timeline_wrapper {
  margin-top: 30px;
}

.salt_profile {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.salt_profile_username {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.salt_profile_username .h5 {
  font-size: 18px;
  margin-bottom: 1px;
}

.salt_profile_username .h6 {
  font-size: 12px;
  color: #808191;
}

.salt__profile_wrapper {
  width: 100%;
}

.salt__carousel_container {
  margin-top: 30px;
}

.salt__score_fixed {
  position: relative;
}

.salt__score_fixed {
  transition: all 0.3s ease;
  width: 300px;
}

.prize__wrapper {
  display: grid;
  grid-template-rows: repeat(auto-fill, minmax(150px, 1fr));
  grid-gap: 30px;
}

.zero__fee_text_container {
  width: 100%;
  height: fit-content;
  padding: 8px 18px;
  font-family: 'Poppins', sans-serif;
  font-size: 13px;
  position: relative;
  text-align: center;
  margin-bottom: 20px;
}

.zero__fee_text_container span {
  color: #808191;
}

.zero__fee_text_container::before {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 10px;
  padding: 1px;
  background: linear-gradient(180deg, #8173e0 0%, #ba77f7 100%);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

@media (min-width: 1260px) {
  /* Your CSS rules for screens wider than 1500px go here */
  /* .salt__score_fixed {
    position: fixed;
    width: 290px;
  } */

  .salt__score_fixed.fixed {
    position: fixed;
    top: 30px;
  }
}
