.search__container {
  position: relative;
  padding: 48px;
  border-radius: 24px;
  background: rgb(108, 93, 211);
  background: linear-gradient(
    70deg,
    rgba(108, 93, 211, 1) 37%,
    rgba(96, 105, 211, 1) 58%,
    rgba(65, 134, 210, 1) 94%
  );
  color: #ffffff;
}

.leaderboard__prize {
  background-color: #191b20;
  /* width: 100% !important; */
  /* width: 96% !important; */
  /* border-radius: 0px !important; */
  /* margin-right: 12px; */
}

.dark .prize.leaderboard__prize {
  background-color: #191b20;
}

/* .leaderboard__prize_first_br {
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}

.leaderboard__prize_second_br {
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.react-multi-carousel-item--active:nth-child(2n + 1) .leaderboard__prize {
  border-radius: 0px;
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}

.react-multi-carousel-item--active:nth-child(2n + 2) .leaderboard__prize {
  border-radius: 0px;
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
} */

/* .leaderboard__prize:not(:first-child) {
  margin-right: 5px;
} */

/* .react-multi-carousel-item--active:nth-child(3n + 1) {
  border: 2px solid red !important;
} */

/* Style the second active element */
/* .react-multi-carousel-item--active:nth-child(3n + 2) {
  border: 2px solid blue !important;
} */

/* .leaderboard__prize_second_border {
  border-right: 1px solid #e4e4e4;
}

.dark .leaderboard__prize_second_border {
  border-color: rgba(228, 228, 228, 0.1);
} */

.search__wrap {
  position: relative;
  z-index: 3;
  max-width: 380px;
  margin-bottom: 36px;
}

.search__title {
  margin-bottom: 16px;
}

.search__form {
  position: relative;
  z-index: 3;
  max-width: 496px;
  -webkit-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.07);
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.07);
}

.search__input {
  width: 100%;
  height: 64px;
  padding: 0 64px 0 30px;
  border-radius: 16px;
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  line-height: 1.33333;
  font-weight: 500;
  color: #11142d;
}

.search__input::-webkit-input-placeholder {
  color: #808191;
}

.search__input::-moz-placeholder {
  color: #808191;
}

.search__input::-ms-input-placeholder {
  color: #808191;
}

.search__input::placeholder {
  color: #808191;
}

.search__btn {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 64px;
  font-size: 0;
}

.search__btn .icon {
  font-size: 20px;
  fill: #11142d;
  width: 19px;
  -webkit-transition: fill 0.2s;
  -o-transition: fill 0.2s;
  transition: fill 0.2s;
}

.search__btn:hover .icon {
  fill: #6c5dd3;
}

.search__preview {
  position: absolute;
  top: -27px;
  left: 50%;
  width: 740px;
}

.search__preview img {
  width: 100%;
}

.search:not(:last-child) {
  margin-bottom: 136px;
}

.categories__title {
  margin-bottom: 48px;
}

.categories__container {
  margin: 0 -12px;
}

.categories__item {
  display: block;
  height: 100%;
  margin: 0 12px;
  padding: 36px 32px 32px;
  border-radius: 20px;
  background: #ffffff;
  -webkit-box-shadow: 0 15px 35px rgba(0, 0, 0, 0.07);
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.07);
  text-align: center;
  font-size: 16px;
  line-height: 1.25;
  font-weight: 600;
  color: #11142d;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.categories__item:hover {
  -webkit-box-shadow: 0 0 0 1px #6c5dd3;
  box-shadow: 0 0 0 1px #6c5dd3;
  color: #8173e0;
}
.prices__text a {
  text-decoration: none;
  color: inherit;
}

.prices__text a:hover {
  text-decoration: underline;
}

.categories__item.active {
  -webkit-box-shadow: 0 0 0 1px #6c5dd3;
  box-shadow: 0 0 0 1px #6c5dd3;
  color: #8173e0;
}

.categories__preview {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 50px;
  height: 50px;
  margin: 0 auto 10px;
  border-radius: 50%;
}

.categories__preview img {
  max-height: 22px;
}

.categories .owl-stage {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.categories .owl-item {
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.categories .owl-item.active {
  visibility: visible;
  opacity: 1;
}

.categories .owl-carousel .owl-stage-outer {
  overflow: visible;
}

.categories .owl-dots {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 46px;
}

.categories .owl-carousel button.owl-dot {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 24px;
  height: 2px;
  border-radius: 1px;
  background: #e4e4e4;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.categories .owl-carousel button.owl-dot.active {
  background: #6c5dd3;
}

.categories .owl-carousel button.owl-dot:not(:last-child) {
  margin-right: 4px;
}

.categories:not(:last-child) {
  margin-bottom: 80px;
}

.dark .categories__item {
  background: #242731;
  color: #ffffff;
}

.dark .categories .owl-carousel button.owl-dot {
  background: rgba(255, 255, 255, 0.5);
}

.dark .categories .owl-carousel button.owl-dot.active {
  background: #6c5dd3;
}

.prices__head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 48px;
}

.prices__table {
  display: table;
  width: 100%;
}

.prices__row {
  display: table-row;
  border-radius: 12px;
  cursor: pointer;
  color: #11142d;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.prices__row:nth-child(2n + 1) {
  background: rgba(228, 228, 228, 0.2);
}

.prices__row:not(.prices__row_head):not(.leaderBoard__row):hover {
  background: none;
  -webkit-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.07);
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.07);
}

.prices__cell {
  display: table-cell;
  vertical-align: middle;
  height: 72px;
  padding-left: 16px;
  font-weight: 600;
}

.prices__row a {
  text-decoration: none;
  color: inherit;
}

.prices__row a:hover {
  text-decoration: underline;
}

.prices__cell:first-child {
  border-radius: 12px 0 0 12px;
  font-size: 0;
}

.prices__cell:nth-child(3) {
  font-weight: 600;
  color: #808191;
}

.prices__cell:last-child {
  padding-right: 16px;
  border-radius: 0 12px 12px 0;
}

.prices__row_head .prices__cell {
  height: 40px;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #808191;
}

.prices__row_head .prices__cell:first-child {
  border-radius: 8px 0 0 8px;
}

.prices__row_head .prices__cell:last-child {
  border-radius: 0 8px 8px 0;
}

.prices__company {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: 600;
}

.prices__logo {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  margin-right: 16px;
}

.prices__logo img {
  width: 100%;
  min-height: 100%;
  border-radius: 50%;
}

.prices__chart {
  position: relative;
  bottom: -4px;
  width: 112px;
  height: 30px;
}

.prices__chart .apexcharts-canvas svg {
  overflow: visible;
}

.prices__btns {
  margin-top: 40px;
  text-align: center;
}

.dark .prices__row {
  color: #ffffff;
}

.dark .prices__row:nth-child(2n + 1) {
  background: rgba(228, 228, 228, 0.03);
}

.dark .prices__row:not(.prices__row_head):not(.leaderBoard__row):hover {
  background: #242731;
}

.categories__item.your__score:hover .categories__item {
  border: none;
}

.your__score {
  /* box-shadow: 0 0 0 1px #6c5dd3;
  width: 'fit-content';
  text-align: 'left'; */
  background-color: transparent;
}

.dark .your__score {
  background-color: transparent;
}

.your__score:hover {
  box-shadow: none;
}

.leaderboard__title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.leaderboard__title .categories__title {
  margin-bottom: 0px;
}

.leaderBoard__row:not(.leaderBoard__row_head).active {
  background: none;
  -webkit-box-shadow: 0 0 0 2px #6c5dd3;
  box-shadow: 0 0 0 2px #6c5dd3;
}

.leader_board_link:nth-child(1) {
  margin-left: -12px;
}

.leader_board_link:nth-child(1) {
  margin-left: -12px;
}

.leader_board_link .active__tags {
  background: #808191;
  color: #fff;
  padding: 2px 10px;
  border-radius: 4px;
  margin-left: 8px;
}

.leader_board_link .active__tags.active {
  background: #47c862;
}

.trade__details_text_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #808191;
}

.trade__details_text_container:not(:first-child) {
  margin-top: 15px;
}

.prize__event_container {
  display: flex;
  flex-direction: row;
  /* flex-wrap: wrap; */
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.your_stats .user__score {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
}

.your_stats_without_jwt .user__score {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
}

.your_stats_event_container {
  /* display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 30px; */
}

.prize__container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}

.your_stats_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}

.your_stats span {
  color: #695dc9;
  font-weight: 700;
}

.your_stats li {
  margin-top: 4px;
}

.your_stats ul {
  margin-top: 10px;
}

.your_stats_without_jwt li {
  margin-top: 4px;
}

.your_stats_without_jwt ul {
  margin-top: 10px;
}

.leaderboard__prize {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
}

.leaderboard__prize_item img {
  width: 100px;
  height: 100px;
}

.prize {
  background-color: rgb(225 220 220 / 30%);
  padding: 20px 20px;
  width: 96%;
  border-radius: 10px;
  font-family: 'Poppins', sans-serif;
  position: relative;
}

.prize__carousel_container {
  margin-left: 8px;
}

.your_stats {
  background-color: rgba(228, 228, 228, 0.3);
  padding: 20px 20px;
  width: 250px;
  border-radius: 10px;
  font-family: 'Poppins', sans-serif;
  position: relative;
}

.your_stats_without_jwt {
  background-color: rgba(228, 228, 228, 0.3);
  padding: 20px 20px;
  width: 250px;
  border-radius: 10px;
  font-family: 'Poppins', sans-serif;
  position: relative;
}

.dark .your_stats,
.dark .your_stats_without_jwt {
  background-color: #25272e;
}

.your_stats:nth-child(2) {
  height: 100%;
  width: 197px;
}

.text-mask {
  background: linear-gradient(180deg, #695dc9 24.16%, #ba77f7 66.18%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.your_stats.pro_tip {
  /* width: 197px; */
  height: 181px;
}

.leaderboard_page .react-multi-carousel-list {
  padding-top: 8px;
  padding-bottom: 30px;
}

.flex-item {
  flex: 1;
}

.flex-container {
  display: flex;
  width: 100%;
}

.prize h1 {
  margin-top: 5px;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: 300;
}

.leaderboard__prize__wrapper {
  padding: 30px 20px;
  background: rgba(228, 228, 228, 0.3);
  border-radius: 10px;
}

.dark .leaderboard__prize__wrapper {
  background-color: #25272e;
}

.your_stats p {
  color: #808191;
  font-size: 12px;
}

.your_stats_without_jwt p {
  color: #808191;
  font-size: 12px;
}

.dark .prize {
  background-color: #25272e;
}

.prize img {
  /* height: 70px;
  position: absolute;
  right: 10px;
  top: 15px; */
}

.prize ul {
  color: #808191;
  margin-top: 5px;
  padding-left: 20px;
}

.prize ul li {
  list-style-type: none;
  margin-left: -19px;
  margin-top: 5px;
}

.prize:not(:nth-child(1)) {
  margin-left: 20px;
}

.trade__details_container_wrappper {
  flex-direction: row;
  justify-content: space-between;
}

.trade__details_container_wrappper .event_timeline {
  height: fit-content;
}

.event_timeline {
  font-family: 'Poppins', sans-serif;
  border: 1px solid #6c5dd3;
  padding: 10px 34px 10px 40px;
  border-radius: 15px;
  text-align: center;
  background-color: rgba(108, 93, 211, 0.1);
}

.event_timeline h1 {
  font-size: 18px;
}

.campaign__timer {
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.campaign__timer h1 {
  font-size: 18px;
}

.campaign__timer p {
  color: #808191;
  font-size: 11px;
}

.dark .home__leaderboard_box {
  box-shadow: 0 8px 25px rgb(0 0 0 / 7%);
  border: none;
}
.leaderboard__select {
  margin-left: 0px !important;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.prize__item {
  /* margin-left: 10px; */
}

.winner__item {
  width: 100%;
}

.winner__item .profile__picture {
  position: relative;
  width: 50px;
  height: 50px;
  object-fit: cover;
  right: 0px;
  top: 0px;
  border-radius: 25px;
  margin-right: 5px;
}

@media only screen and (max-width: 920px) {
  .your_stats_event_container {
    flex-direction: column-reverse;
  }
  .event_timeline {
    margin-bottom: 40px;
  }

  .leaderboard__select {
    margin-top: 20px !important;
    margin-bottom: 0px !important;
  }

  .leaderboard__title {
    margin-bottom: 6px;
  }

  .salt__prize__container {
    display: grid;
    place-items: center;
    grid-template-columns: 50% 50%;
    grid-gap: 20px;
  }

  .prize__container {
    display: grid;
    place-items: center;
    grid-template-columns: 50% 50%;
    grid-gap: 20px;
  }

  .event_timeline {
    margin-top: 40px;
  }

  .prize {
    margin-left: 0px;
    width: 100%;
  }

  .prize:not(:nth-child(1)) {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 897px) {
  .event_timeline {
    margin-top: 40px;
  }

  .prize {
    margin-left: 0px;
    width: 100%;
  }

  .prize:not(:nth-child(1)) {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 700px) {
  /* .prize__event_container {
    display: grid;
    align-content: center;
  } */
  .header__time {
    font-size: 11px;
  }
}
