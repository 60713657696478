.kyc__otp > * {
  margin-bottom: 15px;
}

.close-btn {
  background-color: rgb(218, 82, 82);
}

.close-btn:hover {
  background-color: rgb(247, 114, 114);
}

.green-btn {
  background-color: rgb(69, 199, 126);
}

.green-btn:hover {
  background-color: rgb(41, 128, 79);
}

.camera-btns {
  display: flex;
  margin-top: 30px;
}

.green-outline {
  border: 1px solid rgb(69, 199, 126);
}
.blue-outline {
  border: 1px solid #6c5dd3;
}
.red-outline {
  border: 1px solid rgb(247, 114, 114);
}
.icon-container {
  padding: 10px;
  border-radius: 40px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  cursor: pointer;
}

.camera-actions {
  padding: 10px;
  margin-top: 12px;
  display: flex;
}

.why__kyc {
  color: #8173e0;
  text-decoration: none;
}

.why__kyc:hover {
  color: #8173e0;
  text-decoration: none;
}

.link_items:hover td {
  color: #8173e0;
}

.link_items td {
  display: flex;
  justify-content: center;
  align-items: center;
}

.camera-btns > * {
  margin-right: 12px;
}

.camera-actions > * {
  margin-right: 12px;
}

.refer__earn_setting {
  padding: 18px 25px;
  border-radius: 20px;
  /* border: #c9cad7 1px solid; */
  margin-top: 32px;
  background-color: rgba(228, 228, 228, 0.3);
}

.dark .refer__earn_setting {
  /* border: #808191 1px solid; */
  background-color: #191b20;
}

.refer__earn_setting h1 {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 16px;
}

.refer__earn_setting p {
  font-family: 'Poppins', sans-serif;
  margin-top: 12px;
  margin-bottom: 12px;
  font-size: 12px;
  color: #808191;
  max-width: 300px;
}

.refer__earn_setting {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.refer__earn_setting img {
  object-fit: cover;
  width: 150px;
}
