* {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.server__down {
  display: grid;
  place-items: center;
}

.server__down span {
  color: #8173e0;
}

.server__down a {
  color: #8173e0;
  text-decoration: none;
}

.server__down p {
  max-width: 520px;
  text-align: center;
  padding: 10px 10px 20px 10px;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
}
.server__down img {
  max-height: 350px;
}

.server__down .h2 {
  max-width: 620px;
  text-align: center;
  padding: 30px 0px 0px 0px;
}

.server__down button {
  margin-bottom: 30px;
}

.server__down ul {
  font-family: 'Poppins', sans-serif;
  margin-bottom: 20px;
  list-style-type: circle;
  margin-left: 20px;
}

.server__down ul li {
  margin: 5px 0px;
}

.server__down .oops {
  color: #808191;
  font-size: 2.5em;
  letter-spacing: 0.05em;
  margin-top: 30px;
  text-align: center;
}

@media only screen and (max-width: 600px) {
  .server__down ul {
    padding: 20px;
  }
  .server__down .h2 {
    padding: 20px;
  }
}
