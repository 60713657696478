.discount-container {
  /* left: 0; */
  color: #808191;
  font-family: 'Poppins', sans-serif;
  padding: 8px 0;
  z-index: 1000;
  margin-top: -12px;
  font-size: 11px;
}

.discount-container .countdown-timer {
  display: flex;
  gap: 16px;
}

.countdown-timer .group {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
}

.countdown-timer .value {
  font-size: 12px;
  font-weight: bold;
  padding: 4px;
  width: 33px;
  text-align: center;
  border-radius: 4px;
}

.countdown-timer .value {
  background: rgba(228, 228, 228, 0.3);
  color: #000;
}

.dark .countdown-timer .value {
  background: #191b20;
  color: #fff;
}

.countdown-timer .unit {
  font-size: 11px;
}
