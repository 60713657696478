/* STEPPER  */

.register-stepper {
  display: table;
  width: 100%;
  margin: 0 auto;
}
.register-stepper .step {
  display: table-cell;
  position: relative;
  padding: 1.5rem;
  z-index: 2;
  width: 25%;
}
.register-stepper .step:last-child .step-bar-left,
.register-stepper .step:last-child .step-bar-right {
  display: none;
}
.register-stepper .step .step-circle {
  width: 2rem;
  height: 2rem;
  margin: 0 auto;
  border-radius: 50%;
  text-align: center;
  line-height: 1.75rem;
  font-size: 1rem;
  font-weight: 600;
  z-index: 2;
  border: 2px solid #6c5dd3;
}

.register-stepper .step .step-circle {
  width: 2rem;
  height: 2rem;
  margin: 0 auto;
  border-radius: 50%;
  text-align: center;
  line-height: 1.75rem;
  font-size: 1rem;
  font-weight: 600;
  z-index: 2;
  border: 2px solid #6c5dd3;
}
.register-stepper .step.done .step-circle {
  background-color: #6c5dd3;
  border: 2px solid #6c5dd3;
  color: #ffffff;
}

.register-stepper.active_all .step .step-circle {
  background-color: #6c5dd3 !important;
  border: 2px solid #6c5dd3;
  color: #ffffff !important;
}

.register-stepper.salt-stepper.active_all .step .step-circle {
  background-color: rgba(228, 228, 228, 0.3) !important;
  border: 2px solid rgba(228, 228, 228, 0.3);
  color: #000 !important;
}

.dark .register-stepper.salt-stepper.active_all .step .step-circle {
  background-color: #33353a !important;
  border: 2px solid #33353a;
  color: #ffffff !important;
}

.register-stepper.salt-stepper .step.incomplete .step-circle {
  border: 2px solid transparent;
  color: #000 !important;
  opacity: 0.3;
  background-color: rgba(228, 228, 228, 0.3) !important;
}

.dark .register-stepper.salt-stepper .step.incomplete .step-circle {
  background-color: #33353a !important;
  color: #fff !important;
}

.register-stepper.salt-stepper .step.incomplete .step-circle {
  background-color: rgba(228, 228, 228, 0.3) !important;
  color: #000 !important;
}

.register-stepper .step.done .step-circle:before {
  font-weight: 300;
  /* content: '\2713'; */
  font-family: 'Font Awesome 5 Free'; /* Adjust this based on your Font Awesome setup */
  content: '\f00c';
  font: var(--fa-font-solid);
}
.register-stepper .step.done .step-circle * {
  display: none;
}
.register-stepper .step.done .step-title {
  color: #8173e0;
}

.register-stepper .step.editing .step-circle {
  background: #ffffff;
  border-color: #6c5dd3;
  color: #8173e0;
}

.dark .register-stepper.salt-stepper .step.editing .step-circle {
  background: #33353a;
  border-color: #33353a;
  color: #fff;
}

.register-stepper.salt-stepper .step.editing .step-circle {
  background: rgba(228, 228, 228, 0.3);
  border-color: rgba(228, 228, 228, 0.3);
  color: #000;
}

/* .register-stepper .step.editing .step-title {
  color: #8173E0;
} */
.register-stepper .step .step-title {
  margin-top: 1rem;
  font-size: 13px;
  font-weight: 100;
}
.register-stepper .step .step-title {
  text-align: center;
}

.register-stepper .step .step-bar-left,
.register-stepper .step .step-bar-right {
  position: absolute;
  top: calc(2rem + 5px);
  height: 5px;
  /* background-color: #d9e2ec; */
  border: solid #d9e2ec;
  border-width: 2px 0;
}
.register-stepper .step .step-bar-left {
  width: calc(100% - 2rem);
  left: 50%;
  margin-left: 1rem;
  z-index: -1;
}
.register-stepper .step .step-bar-right {
  width: 0;
  left: 50%;
  margin-left: 1rem;
  z-index: -1;
  transition: width 500ms ease-in-out;
}
.register-stepper .step.done .step-bar-right {
  /* background-color: #6c5dd3;
  border-color: #6c5dd3; */
  z-index: 3;
  width: calc(100% - 2rem);
}

.register-stepper.active_all .step .step-bar-left,
.register-stepper.active_all .step .step-bar-right {
  height: 2px;
  border-top: dashed rgba(128, 129, 143, 1) 2px !important;
  top: calc(2.2rem + 5px) !important;
}

.register-stepper.salt-stepper .step .step-bar-left,
.register-stepper.salt-stepper .step .step-bar-right {
  height: 2px;
  border-top: dashed rgba(128, 129, 143, 1) 2px !important;
  top: calc(2.2rem + 5px) !important;
}

.register-stepper.active_all .step .step-bar-left,
.register-stepper.active_all .step .step-bar-right {
  border: none;
}

.register-stepper.salt-stepper .step .step-bar-left,
.register-stepper.salt-stepper .step .step-bar-right {
  border: none;
}

.dark .register-stepper .step.editing .step-circle {
  background-color: #333;
}

.dark .register-stepper .step .step-bar-left,
.dark .register-stepper .step .step-bar-right {
  /* background-color: #333; */
  /* border: solid #333; */
}
