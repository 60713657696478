.page {
  overflow: hidden;
}

.page__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 100vh;
  padding-left: 255px;
  -webkit-transition: padding 0.2s;
  -o-transition: padding 0.2s;
  transition: padding 0.2s;
}

.page__content.show-banner {
  margin-top: 60px;
}

.page__content.show-banner .sidebar {
  top: 60px;
}

.page__container {
  padding: 0 64px 64px;
}

.page__row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.page__col {
  padding: 0 64px 64px;
}

.page__col:first-child {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 calc(100% - 426px);
  flex: 0 0 calc(100% - 426px);
  max-width: calc(100% - 426px);
}

.page__col:nth-child(2) {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 426px;
  flex: 0 0 426px;
  width: 426px;
  border-left: 1px solid #e4e4e4;
}

.page__col_pt100 {
  padding-top: 100px;
}

.page__col_pt198 {
  padding-top: 198px;
}

.page__head {
  margin-top: -80px;
  margin-bottom: 32px;
}

.page__col > .page__title {
  margin-bottom: 32px;
}

.page.wide .page__content,
.page.full .page__content {
  padding-left: 96px;
}

.page.full.wide .page__content {
  padding-left: 96px;
}

.dark .page__col:nth-child(2) {
  border-color: rgba(228, 228, 228, 0.1);
}

.top_container {
  background-color: #faeee5;
  text-align: center;
  color: #8173e0;
  font-family: 'Poppins';
  font-size: 12px;
  padding: 3px;
  z-index: 100;
  position: fixed;
  width: 100%;
}

.dark .top_container {
  background-color: #323232;
}

.top_notification {
  margin-left: 176px;
}

.top_container span {
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
}

.feedback__popup {
  display: grid;
  place-items: center;
}

.feedback-container {
  position: fixed;
  right: 20px;
  bottom: 20px;
  border: 1px #6c5dd3 solid;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  animation: shake-animation 4.72s ease infinite;
  background-color: #fff;
  padding: 5px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 80;
  transition: all 0.6s;
}

.send-us-feedback {
  box-shadow: 0 15px 35px rgb(0 0 0 / 7%);
  padding: 10px;
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
  border-radius: 10px;
  margin-left: -10px;
}

.dark .feedback-container {
  background: #242731;
  color: #fff;
}

.feedback__popup textarea {
  width: 100%;
  height: 80px;
  padding: 18px 22px 0;
  border-radius: 12px;
  border: 2px solid transparent;
  background: rgba(228, 228, 228, 0.3);
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #11142d;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  resize: vertical;
  min-height: 150px;
  margin-top: 4px;
}

.feedback__popup input {
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  border: 2px solid transparent;
  background: rgba(228, 228, 228, 0.3);
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #11142d;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  resize: vertical;
  margin-top: 4px;
  margin-bottom: 20px;
}

.feedback_popup_container {
  margin-top: 30px;
  font-family: 'Poppins', sans-serif;
}

.feedback_popup_container h2 {
  text-align: center;
}

.feedback_collaspe_icon i {
  font-size: 19px;
}

.send-us-feedback.close {
  display: none;
}

.feedback__popup input::placeholder {
  color: #b1b2b7;
}

.dark .feedback__popup input::placeholder {
  color: #787878;
}

.feedback__popup textarea:focus {
  border-color: #dcdde4;
  background: #ffffff;
}

.feedback__popup input:focus {
  border-color: #dcdde4;
  background: #ffffff;
}

.dark .feedback__popup textarea:focus {
  border-color: #808191;
}

.dark .feedback__popup textarea {
  background: rgba(228, 228, 228, 0.03);
  color: #ffffff;
}

.dark .feedback__popup input {
  background: rgba(228, 228, 228, 0.03);
  color: #ffffff;
}

.dark .feedback__popup input:focus {
  border-color: #808191;
}

.feedback__email_icon .icon {
  width: 14px;
  margin-left: 4px;
  margin-top: -2px;
  cursor: pointer;
}
