/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8;
}

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden;
}

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.mfp-align-top .mfp-container:before {
  display: none;
}

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045;
}

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto;
}

.mfp-ajax-cur {
  cursor: progress;
}

.mfp-zoom-out-cur,
.mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -webkit-zoom-out;
  cursor: zoom-out;
}

.verify__email > * {
  margin-bottom: 22px;
}

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: zoom-in;
}

.mfp-auto-cursor .mfp-content {
  cursor: auto;
}

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.mfp-loading.mfp-figure {
  display: none;
}

.mfp-hide {
  display: none !important;
}

.mfp-preloader {
  color: #ccc;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044;
}

.mfp-preloader a {
  color: #ccc;
}

.mfp-preloader a:hover {
  color: #fff;
}

.mfp-s-ready .mfp-preloader {
  display: none;
}

.mfp-s-error .mfp-content {
  display: none;
}

button.mfp-close,
button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  -webkit-box-shadow: none;
  box-shadow: none;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #fff;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace;
}

.mfp-close:hover,
.mfp-close:focus {
  opacity: 1;
}

.mfp-close:active {
  top: 1px;
}

.mfp-close-btn-in .mfp-close {
  color: #333;
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #fff;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%;
}

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #ccc;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap;
}

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: transparent;
}

.mfp-arrow:active {
  margin-top: -54px;
}

.mfp-arrow:hover,
.mfp-arrow:focus {
  opacity: 1;
}

.mfp-arrow:before,
.mfp-arrow:after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 35px;
  margin-left: 35px;
  border: medium inset transparent;
}

.mfp-arrow:after {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px;
}

.mfp-arrow:before {
  border-top-width: 21px;
  border-bottom-width: 21px;
  opacity: 0.7;
}

.mfp-arrow-left {
  left: 0;
}

.mfp-arrow-left:after {
  border-right: 17px solid #fff;
  margin-left: 31px;
}

.mfp-arrow-left:before {
  margin-left: 25px;
  border-right: 27px solid #3f3f3f;
}

.mfp-arrow-right {
  right: 0;
}

.mfp-arrow-right:after {
  border-left: 17px solid #fff;
  margin-left: 39px;
}

.mfp-arrow-right:before {
  border-left: 27px solid #3f3f3f;
}

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px;
}

.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 900px;
}

.mfp-iframe-holder .mfp-close {
  top: -40px;
}

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%;
}

.mfp-iframe-scaler iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #000;
}

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto;
}

/* The shadow behind the image */
.mfp-figure {
  line-height: 0;
}

.mfp-figure:after {
  content: '';
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #444;
}

.mfp-figure small {
  color: #bdbdbd;
  display: block;
  font-size: 12px;
  line-height: 14px;
}

.mfp-figure figure {
  margin: 0;
}

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto;
}

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #f3f3f3;
  word-wrap: break-word;
  padding-right: 36px;
}

.mfp-image-holder .mfp-content {
  max-width: 100%;
}

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer;
}

.popup {
  position: relative;
}

.popup > .popup__title {
  margin-bottom: 48px;
}

.popup__select {
  margin-bottom: 24px;
}

.popup__nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 -4px 28px;
}

.popup__nav .popup__link {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  margin: 0 4px;
  border: 1px solid #e4e4e4;
  border-radius: 20px;
  text-align: center;
  line-height: 38px;
  font-weight: 600;
  color: #11142d;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.popup__nav .popup__link:hover {
  color: #8173e0;
}

.popup__nav .popup__link.active {
  background: rgba(228, 228, 228, 0.3);
  border-color: transparent;
  color: #8173e0;
}

.popup__item {
  display: none;
}

.popup__user:not(:last-child) {
  margin-bottom: 32px;
  padding-bottom: 32px;
  border-bottom: 1px solid #e4e4e4;
}

.popup__line {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.popup__ava {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 80px;
  height: 80px;
  margin-right: 24px;
}

.popup__ava .popup__pic {
  /* width: 100%;
  min-height: 100%;
  border-radius: 50%;
  image-orientation: from-image; */

  min-width: 100%;
  height: 75px;
  border-radius: 50%;
  overflow: hidden;
  object-fit: cover;
}

.popup__details {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.popup__btns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 7px;
}

.popup__btns .popup__btn:not(:last-child),
.popup__loading:not(:last-child) {
  margin-right: 8px;
}

.popup__loading {
  position: relative;
  overflow: hidden;
}

.popup__file {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 140px;
  opacity: 0;
}

.popup__upload {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-width: 131px;
  height: 48px;
  border: 2px solid #6c5dd3;
  border-radius: 12px;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 700;
  color: #11142d;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.popup__loading:hover .popup__upload {
  background: #6c5dd3;
  color: #ffffff;
}

.popup__default {
  color: #8173e0;
  font-family: 'Poppins';
  font-size: 12px;
}

.popup__fieldset {
  margin-bottom: 20px;
}

.popup__fieldset > .popup__field:not(:last-child) {
  margin-bottom: 20px;
}

.popup__row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 -8px;
}

.popup__row:not(:last-child) {
  margin-bottom: 20px;
}

.popup__row .popup__field {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 calc(50% - 16px);
  flex: 0 0 calc(50% - 16px);
  width: calc(50% - 16px);
  margin: 0 8px;
}

.popup__label {
  margin-bottom: 16px;
  font-size: 12px;
  line-height: 1.33333;
  font-weight: 500;
  color: #808191;
}

.popup__label_mt22 {
  margin-top: 22px;
}

.popup__wrap {
  position: relative;
}

.popup__input {
  width: 100%;
  height: 56px;
  padding: 0 20px;
  border-radius: 8px;
  background: rgba(228, 228, 228, 0.3);
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #11142d;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.popup__input:focus {
  -webkit-box-shadow: inset 0 0 0 2px #6c5dd3;
  box-shadow: inset 0 0 0 2px #6c5dd3;
  background: #ffffff;
}

.popup__input.notes__element:focus {
  -webkit-box-shadow: inset 0 0 0 2px rgba(108, 93, 211, 0.7);
  box-shadow: inset 0 0 0 2px rgba(108, 93, 211, 0.7);
  background: #ffffff;
}

.popup__logout {
  display: grid;
  place-items: center;
  margin-top: 32px;
  padding-top: 32px;
  border-top: 1px solid #e4e4e4;
}

.popup__variants {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-bottom: 24px;
  padding: 24px;
  border-radius: 8px;
  background: rgba(228, 228, 228, 0.3);
}

.popup__variants .checkbox:not(:last-child) {
  margin-bottom: 20px;
}

.popup__invite {
  position: relative;
  margin-bottom: 48px;
  padding: 32px;
  border-radius: 16px;
  /* background: rgb(96, 105, 211);
  background: linear-gradient(
    100deg,
    rgba(65, 134, 210, 1) 52%,
    rgba(96, 105, 211, 1) 74%
  ); */
  background: #ffffff;
  box-shadow: 0 8px 293px rgba(0, 0, 0, 0.07);
}

.dark .popup__invite {
  background: #242731;
  color: #ffff;
}

.popup__input::placeholder {
  color: rgb(194, 194, 194);
  font-size: 10px;
  font-weight: 600;
}

/* .popup__invite:before,
.popup__invite:after {
  content: '';
  position: absolute;
  border-radius: 16px;
} */

.file_input__document {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 60px;
  opacity: 0;
  width: 100%;
}

.popup__invite:before {
  top: 16px;
  left: 16px;
  right: 16px;
  bottom: -16px;
  z-index: -2;
  background: rgba(105, 85, 235, 0.2);
}

.popup__invite:after {
  top: 8px;
  left: 8px;
  right: 8px;
  bottom: -8px;
  z-index: -1;
  background: rgb(28 70 231 / 57%);
}

.popup__invite .popup__info {
  margin-bottom: 24px;
}

.popup__invite .popup__label {
  color: #ffffff;
  font-family: 'Poppins', sans-serif;
}

.popup__invite .popup__input {
  padding-right: 56px;
  background: rgba(228, 228, 228, 0.24);
  color: #000;
}

.dark .popup__invite .popup__input {
  color: #fff;
}

.popup__invite .popup__input:focus {
  -webkit-box-shadow: inset 0 0 0 2px #6c5dd3;
  box-shadow: inset 0 0 0 2px #6c5dd3;
  background: rgba(228, 228, 228, 0.24);
}

.popup__copy {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 56px;
  font-size: 0;
}

.popup__copy .icon {
  font-size: 14px;
  -webkit-transition: opacity 0.2s;
  -o-transition: opacity 0.2s;
  transition: opacity 0.2s;
}

.popup__copy:hover .icon {
  opacity: 0.7;
}

.popup__category {
  margin-bottom: 12px;
  font-size: 16px;
  line-height: 1.25;
  font-weight: 600;
}

.popup__flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.popup__currency {
  display: inline-block;
  padding: 6px 13px;
  border-radius: 16px;
  background: #3dbaa2;
  font-size: 16px;
  line-height: 1.25;
  font-weight: 600;
  color: #ffffff;
}

.popup__currency:not(:first-child) {
  margin-left: 20px;
}

.popup__parameters {
  margin-top: 32px;
}

.popup__parameter {
  padding: 32px 0;
  border-top: 1px solid #e4e4e4;
}

.popup__parameter:last-child {
  padding-bottom: 0;
}

.popup__head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-size: 16px;
  line-height: 1.25;
  font-weight: 600;
}

.popup__body {
  max-width: 250px;
  margin-top: 12px;
  color: #808191;
}

.popup__form {
  max-width: 412px;
  margin: 60px auto;
}

.popup__title {
  margin-bottom: 32px;
}

.popup__form .popup__field:not(:last-child) {
  margin-bottom: 16px;
}

.popup__box:not(:last-child) {
  margin-bottom: 28px;
}

.popup__scan {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.popup__scan .popup__preview {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 205px;
}

.popup__scan .popup__preview img {
  width: 100%;
}

.popup__scan .popup__details {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  padding-left: 80px;
}

.popup__scan .popup__text {
  margin-bottom: 20px;
  color: #808191;
  font-family: 'Poppins', sans-serif;
}

.popup__scan .popup__text a {
  color: #8173e0;
}

.popup__scan .popup__text a:hover {
  text-decoration: underline;
}

.popup__numbers {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 -8px;
}

.popup__number {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  margin: 0 8px;
}

.popup__number input {
  width: 100%;
  height: 80px;
  border-radius: 12px;
  background: rgba(228, 228, 228, 0.3);
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-size: 32px;
  line-height: 1.25;
  letter-spacing: -0.5px;
  font-weight: 500;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.popup__number input:focus {
  -webkit-box-shadow: inset 0 0 0 2px #6c5dd3;
  box-shadow: inset 0 0 0 2px #6c5dd3;
  background: #ffffff;
}

.popup__number.success input {
  background: #ffffff;
  -webkit-box-shadow: inset 0 0 0 2px #4fbf67;
  box-shadow: inset 0 0 0 2px #4fbf67;
}

.popup__table {
  width: 100%;
  font-family: 'Poppins', sans-serif;
}

.popup__table tr {
  cursor: pointer;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

/* .popup__table tr:first-child {
  background: rgba(228, 228, 228, 0.2);
} */

.popup__table tr:not(:first-child):hover {
  background: rgba(228, 228, 228, 0.2);
}

.popup__table tr:not(:first-child):hover .popup__close .icon {
  fill: #ffffff;
}

.popup__table tr:not(:first-child):hover th,
.popup__table tr:not(:first-child):hover td {
  color: #ffffff;
}

.popup__table tr:not(:first-child):hover th .icon,
.popup__table tr:not(:first-child):hover td .icon {
  fill: #ffffff;
}

.popup__table tr th,
.popup__table tr td {
  height: 40px;
  vertical-align: middle;
  padding-right: 16px;
  -webkit-transition: color 0.2s;
  -o-transition: color 0.2s;
  transition: color 0.2s;
}

.popup__table tr th:first-child,
.popup__table tr td:first-child {
  padding-left: 16px;
  border-radius: 8px 0 0 8px;
}

.popup__table tr th:last-child,
.popup__table tr td:last-child {
  border-radius: 0 8px 8px 0;
  text-align: right;
  /* font-size: 0; */
}

.popup__table tr th:last-child .icon,
.popup__table tr td:last-child .icon {
  font-size: 14px;
}

.notes__instant {
  margin-top: 22px;
}

.notes__instant .h6 {
  /* text-decoration: underline; */
  /* color: rgb(218, 120, 120); */
  margin-left: 12px;
}

.notes__instant ul {
  padding: 15px 10px 0px 10px;
  margin-left: 12px;
  margin-top: 12px;
  font-family: 'Poppins', sans-serif;
  color: #808191;
}

.notes__instant li {
  /* margin-bottom: 12px;
  list-style-type: circle; */
}

.popup__table tr th {
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  color: #808191;
}

.popup__table tr td {
  font-size: 12px;
  line-height: 1.33333;
  font-weight: 500;
  color: #808191;
}

.popup__close {
  font-size: 0;
}

.popup__close .icon {
  font-size: 14px;
  -webkit-transition: fill 0.2s;
  -o-transition: fill 0.2s;
  transition: fill 0.2s;
}

.popup__flag {
  margin-right: 5px;
}

.popup .mfp-close {
  position: absolute;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  -webkit-box-shadow: 0 5px 20px rgba(227, 230, 236, 0.85);
  box-shadow: 0 5px 20px rgba(227, 230, 236, 0.85);
  line-height: normal;
  opacity: 1;
  font-size: 0;
  background: #ffffff
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 10 10'%3E%3Cpath fill='%2311142d' d='M1.613.2l.094.083L5 3.585 8.293.293a1 1 0 0 1 1.414 0 1 1 0 0 1 .083 1.32l-.083.094L6.415 5l3.292 3.293a1 1 0 0 1-1.32 1.497l-.094-.083L5 6.415 1.707 9.707A1 1 0 0 1 .21 8.387l.083-.094L3.585 5 .293 1.707a1 1 0 0 1 0-1.414A1 1 0 0 1 1.613.21z'/%3E%3C/svg%3E")
    no-repeat 50% 50%/10px auto;
  -webkit-transition: -webkit-box-shadow 0.25s;
  transition: -webkit-box-shadow 0.25s;
  -o-transition: box-shadow 0.25s;
  transition: box-shadow 0.25s;
  transition: box-shadow 0.25s, -webkit-box-shadow 0.25s;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.popup .mfp-close:hover {
  -webkit-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.15);
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.15);
}

.popup_settings {
  max-width: 640px;
  margin: 15px auto;
  padding: 40px 32px;
  background: #ffffff;
  border-radius: 24px;
}

.static__height {
  height: 725px;
}

.popup_settings .mfp-close {
  top: 24px;
  right: 32px;
}

.mfp-container {
  padding: 20px;
}

.mfp-bg {
  background: rgba(27, 29, 33, 0.9);
}

.mfp-zoom-in .popup {
  opacity: 0;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transform: scale(0.8);
}

.mfp-zoom-in.mfp-bg {
  opacity: 0;
  -webkit-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

.mfp-zoom-in.mfp-ready .popup {
  opacity: 1;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  overflow: hidden;
}

.mfp-zoom-in.mfp-ready.mfp-bg {
  opacity: 0.8;
}

.mfp-zoom-in.mfp-removing .popup {
  -webkit-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transform: scale(0.8);
  opacity: 0;
}

.mfp-zoom-in.mfp-removing.mfp-bg {
  opacity: 0;
}

.dark .popup {
  background: #242731;
  border: 1px solid rgba(228, 228, 228, 0.1);
}

.dark .popup__nav .popup__link {
  border-color: rgba(228, 228, 228, 0.1);
  color: #ffffff;
}

.dark .popup__nav .popup__link:hover {
  background: rgba(228, 228, 228, 0.1);
  border-color: transparent;
}

.dark .popup__nav .popup__link.active {
  background: #191b20;
  color: #8173e0;
}

.dark .popup__user {
  border-color: rgba(228, 228, 228, 0.1);
}

.dark .popup__upload {
  color: #ffffff;
}

.dark .popup__field .popup__input {
  background: #191b20;
  color: #ffffff;
}

.dark .popup__field .popup__input:focus {
  background: none;
}

.dark .popup__field .select {
  background: #191b20;
}

.dark .popup__field .select.open {
  background: none;
}

.dark .popup__variants {
  background: rgba(228, 228, 228, 0.03);
}

.dark .popup__parameter {
  border-color: rgba(228, 228, 228, 0.1);
}

.dark .popup__invite .popup__input {
  background: #191b20;
  /* -webkit-box-shadow: 0 0 0 2px #6c5dd3;
  box-shadow: 0 0 0 2px #6c5dd3; */
}

.dark .popup__invite .popup__label {
  color: #fff;
}

.popup__invite .popup__label {
  color: #000;
}

/* .dark .popup__invite .popup__input:focus {
  -webkit-box-shadow: 0 0 0 2px #ffffff;
  box-shadow: 0 0 0 2px #ffffff;
} */

.dark .popup .field__input {
  background: #191b20;
}

.dark .popup .field__input:focus {
  background: none;
}

.dark .popup__number input {
  background: rgba(25, 27, 32, 0.3);
  color: #ffffff;
}

/* .dark .popup__table tr:first-child {
  background: rgba(228, 228, 228, 0.02);
} */

.popup__table tr:hover {
  background: rgba(228, 228, 228, 0.2);
}

.dark .popup__table tr:hover {
  background: rgba(228, 228, 228, 0.02);
}

.dark .popup__table th:last-child .icon,
.dark .popup__table td:last-child .icon {
  fill: #ffffff;
}

.dark .popup .mfp-close {
  background-color: #191b20;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 10 10'%3E%3Cpath fill='%23ffffff' d='M1.613.2l.094.083L5 3.585 8.293.293a1 1 0 0 1 1.414 0 1 1 0 0 1 .083 1.32l-.083.094L6.415 5l3.292 3.293a1 1 0 0 1-1.32 1.497l-.094-.083L5 6.415 1.707 9.707A1 1 0 0 1 .21 8.387l.083-.094L3.585 5 .293 1.707a1 1 0 0 1 0-1.414A1 1 0 0 1 1.613.21z'/%3E%3C/svg%3E");
}

.popup_notification_body {
  padding: 0px 50px 0px 50px;
}

.notification_popup_container {
  margin-top: 8px;
  padding: 30px 50px 30px 50px;
}

.popup_notification_body.popup-text.p {
  max-height: 300px;
  overflow: scroll;
}
