/* .not_found__img {
  width: 70%;
  object-fit: cover;
} */

.no__list {
  text-align: center;
  position: absolute;
  top: 10%;
  left: 0;
  right: 0;
  margin: auto;
  transform: translateY(-10%);
  height: 200px;
  margin-top: 50px;
}

.not__found {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100vh;
}

.not__found .h1 {
  text-transform: uppercase;
}

/* .not__found__img {
  width: 50%;
} */
