.widgets__sorting {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 32px;
  align-items: flex-start;
}

.widgets__sorting .select {
  margin-top: 8px;
  margin-left: 30px;
}

.widgets__variants {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  /* -ms-flex-wrap: wrap; */
  /* flex-wrap: wrap; */
  margin: -8px auto 0 -8px;
}

.lottie_leaderboard {
  top: 25px;
}

.widgets__variants .widgets__link {
  min-width: 100px;
  margin: 8px 0 0 8px;
  padding: 0 8px;
  border-radius: 20px;
  text-align: center;
  font-weight: 600;
  line-height: 40px;
  color: #808191;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.widgets__variants .widgets__link:hover,
.widgets__variants .widgets__link.active {
  background: rgba(228, 228, 228, 0.2);
}

.widgets__variants .widgets__link:hover {
  color: #11142d;
}

.widgets__variants .widgets__link.active {
  color: #8173e0;
}

.widgets__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: -32px -16px 0;
  position: relative;
}

.widgets__item {
  position: relative;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 calc(50% - 32px);
  flex: 0 0 calc(50% - 32px);
  max-width: calc(50% - 32px);
  margin: 32px 16px 0;
  border-radius: 24px;
  background: #ffffff;
  -webkit-box-shadow: 0 8px 25px rgba(0, 0, 0, 0.07);
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.07);
}

.widgets__head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 24px;
  border-bottom: 1px solid #e4e4e4;
}

.widgets__company {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.widgets__logo {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 48px;
  height: 48px;
  margin-right: 12px;
}

.widgets__logo img {
  width: 100%;
  min-height: 100%;
}

.widgets__category {
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  line-height: 1.33333;
  font-weight: 500;
}

.widgets__info {
  font-weight: 600;
  color: #808191;
}

.widgets__time {
  -ms-flex-item-align: start;
  align-self: flex-start;
  padding: 0 8px;
  border-radius: 12px;
  /* background: #808191; */
  /* color: #fff; */
  color: #808191;
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 1.5px;
  line-height: 24px;
  /* color: #8173E0; */
}

.widgets__body {
  padding: 24px;
}

.widgets__line {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 12px;
}

.widgets__price {
  font-family: 'Poppins', sans-serif;
  font-size: 17px;
  line-height: 1.33333;
  font-weight: 500;
}

.total-exchange__action {
  font-size: 10px !important;
}

.widgets__item .widgets__btn {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 0 0 24px 24px;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.widgets__item:hover .widgets__btn {
  visibility: visible;
  opacity: 1;
}

.widgets__btns {
  margin-top: 32px;
  text-align: center;
}

.widgets__chart {
  height: 92px;
  margin-bottom: -30px;
}

.widgets__chart .apexcharts-canvas svg {
  overflow: visible;
}

.widgets__box {
  position: relative;
  padding: 24px 24px 8px;
  -webkit-box-shadow: 0 8px 25px rgba(0, 0, 0, 0.07);
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.07);
  background: #ffffff;
  border-radius: 24px;
  cursor: pointer;
  -webkit-transition: -webkit-box-shadow 0.2s;
  transition: -webkit-box-shadow 0.2s;
  -o-transition: box-shadow 0.2s;
  transition: box-shadow 0.2s;
  transition: box-shadow 0.2s, -webkit-box-shadow 0.2s;
}

.widgets__box a {
  color: #000;
  text-decoration: none;
}

.widgets__box a:hover {
  color: #000;
}

.dark.widgets__box a {
  color: #fff;
  text-decoration: none;
}

.dark.widgets__box a:hover {
  color: #fff;
}

.widgets__box:hover {
  -webkit-box-shadow: inset 0 0 0 2px #6c5dd3;
  box-shadow: inset 0 0 0 2px #6c5dd3;
}

.widgets__box:not(:last-child) {
  margin-bottom: 24px;
}

.widgets__box .status {
  display: inline-block;
}

.widgets__top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 24px;
}

.widgets__box .widgets__price {
  margin-left: -30px;
  font-size: 16px;
  font-weight: 600;
}

.widgets__group {
  text-align: right;
}

.widgets__favorite {
  position: absolute;
  left: 16px;
  bottom: 16px;
  z-index: 3;
  width: 32px;
  height: 32px;
  border-radius: 8px;
  background: #ffffff;
  -webkit-box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16284);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16284);
  font-size: 0;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.apexcharts-legend {
  display: none !important;
}

.apexcharts-pie-series path {
  stroke: #ffff !important;
  /* pointer-events: none !important; */
}

.dark .apexcharts-pie-series path {
  stroke: #242731 !important;
}
.apexcharts-text.apexcharts-pie-label {
  display: none !important;
}

.widgets__favorite .icon {
  font-size: 14px;
  -webkit-transition: fill 0.2s;
  -o-transition: fill 0.2s;
  transition: fill 0.2s;
  width: 15px;
}

.widgets__favorite i {
  font-size: 14px;
  -webkit-transition: fill 0.2s;
  -o-transition: fill 0.2s;
  transition: fill 0.2s;
}

.widgets__favorite:hover .icon {
  fill: #6c5dd3;
}

.widgets__favorite:hover i {
  color: #8173e0;
}

/* .widgets__box:hover .widgets__favorite {
  visibility: visible;
  opacity: 1;
} */

.widgets__box .widgets__favorite {
  visibility: visible;
  opacity: 1;
}

.dark .widgets__variants .widgets__link:hover {
  background: rgba(228, 228, 228, 0.1);
  color: #ffffff;
}

.dark .widgets__variants .widgets__link.active {
  background: #191b20;
  color: #8173e0;
}

.dark .widgets__item,
.dark .widgets__box {
  background: #242731;
}

.dark .widgets__head {
  border-color: rgba(228, 228, 228, 0.1);
}

.dark .widgets__favorite {
  background: #191b20;
}

.dark .widgets__favorite .icon {
  fill: #ffffff;
}

.dark .widgets__favorite i {
  color: #ffffff;
}

.dark .widgets__favorite:hover .icon {
  fill: #6c5dd3;
}

.dark .widgets__favorite:hover i {
  color: #8173e0;
}

.kyc__slider {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding-bottom: 40px;
  padding-top: 20px;
  border-radius: 20px;
  box-shadow: 0 8px 25px rgb(0 0 0 / 7%);
}

.dark .kyc__slider {
  background-color: #242731;
}

.kyc__slider p {
  font-family: 'Poppins', sans-serif;
  margin-top: 12px;
  max-width: 342px;
  font-weight: normal;
}

.kyc__slider button {
  margin-top: 22px;
}

.widgets__search_input {
  margin-top: 12px;
}

.widget_filter__input {
  padding: 20px 20px 20px 20px !important;
  border-radius: 5px;
  /* margin-top: 12px; */
}

.widget_search__container {
  max-width: 180px;
}

.widget_searchicon__container {
  position: absolute;
  right: 4px;
  top: 7px;
}

.widget_search {
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
}

.network__status {
  display: flex;
  justify-content: center;
  align-items: baseline;
  padding-top: 20px;
  width: fit-content;
  flex-wrap: wrap;
  word-wrap: break-word;
}

.network__status img {
  width: 18px;
}

.network__status .h6 {
  color: #808191;
  font-weight: 600;
  margin-left: 18px;
  font-size: 12px;
}

.deposit-popup .popup_settings {
  max-width: 420px;
  height: fit-content;
}

.deposit-popup-title {
  text-align: center;
}

.deposit-popup-container {
  margin-top: 15px;
}

.deposit-dropdown-container .css-1960ht7-control {
  border-color: transparent;
}

.deposit-dropdown-container .dropdown-item {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  border-radius: 10px;
}

.dropdown-item-container {
  width: 100%;
}

.deposit-dropdown-container .dropdown-item {
  position: relative;
}

.deposit-dropdown-container .dropdown-item-symbol {
  position: absolute;
  color: #808191;
  right: 20;
}

.deposit-dropdown-container .css-2b097c-container {
  background-color: rgba(228, 228, 228, 0.3);
  border-radius: 10px;
}

.dark .deposit-dropdown-container .css-2b097c-container {
  background-color: #191b20;
  border-radius: 10px;
}

.deposit-dropdown-container .css-r447wv-singleValue {
  width: 100%;
}

.deposit-dropdown-container .css-r447wv-singleValue span {
  top: 2px;
  right: 8px;
  color: #808191;
}

.deposit-popup-subtitle {
  margin-bottom: 15px;
  font-size: 15px;
}

.deposit__input_wrap {
  position: relative;
}

.deposit__input_wrap .deposit__input_suffix {
  position: absolute;
  right: 20px;
  top: 20px;
}

.deposit__input::placeholder {
  font-size: 14px;
  color: #808191;
  font-family: 'Poppins', sans-serif;
}

.deposit__input_suffix {
  color: #808191;
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
}
.deposit__input_wrap .deposit__input_sub_text {
  position: absolute;
  bottom: 1px;
  left: 20px;
  color: #808191;
  font-family: 'Poppins', sans-serif;
  font-size: 11px;
}

.deposit-info-tiny {
  font-family: 'Poppins', sans-serif;
  font-size: 11px;
  text-align: center;
  color: #808191;
}

.deposit_video_tutorial {
  margin-top: 25px;
  text-decoration: none;
  color: #8173e0;
  font-family: 'Poppins', sans-serif;
  font-size: 13px;
}

.deposit_dropdown {
  width: max-content;
  margin-left: 4px;
}

.deposit-img-container {
  position: relative;
}

.deposit_dropdown .dropdown-network {
  width: 15px;
  height: 15px;
  margin-left: -17px;
  margin-top: 14px;
  z-index: 1000;
  position: relative;
  margin-right: 5px;
  border-radius: 7px;
}

.deposit_dropdown span {
  position: absolute;
  top: 10px;
  right: 15px;
  /* color: #808191; */
}

.leaderboard__description {
  font-size: 14px;
  color: #808191;
  font-family: 'Poppins', sans-serif;
  max-width: 580px;
  margin-bottom: 25px;
  margin-top: 10px;
}

.trade__details_container {
  background-color: rgba(228, 228, 228, 0.3);
  /* padding: 20px 34px; */
  padding: 21px 22px;
  border-radius: 20px;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 15px;
  width: 100%;
  margin-top: 10px;
}

.trade__details_description span.timeline {
  font-size: 13px;
}

.trade__details_text_container.trade__details_title {
  color: #000;
  font-size: 18px;
}

.dark .trade__details_text_container.trade__details_title {
  color: #fff;
}

.dark .trade__details_description {
  color: #fff;
}

.trade__details_description {
  color: #000;
}

.trade__details_container .h5 {
  font-size: 18px;
}

.trade__details_container li {
  margin-bottom: 8px;
  color: #808191;
  font-size: 14px;
}

.trade__details_container li span {
  color: #000;
  font-size: 16px;
}

.dark .trade__details_container li span {
  color: #fff;
}

.dark .trade__details_container {
  background-color: #25272e;
}

.trade__details_container_wrappper {
  display: flex;
  flex-direction: column;
}

.trade__details_container__wrappper_inner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.trade__details_container__timeline {
  height: fit-content !important;
  padding: 10px 20px !important;
  font-size: 10px;
}

.react-multi-carousel-dot-list {
  bottom: 13px !important;
}

.trade__details_title .icon {
  width: 12px;
  margin-left: 4px;
  cursor: pointer;
}

@media screen and (min-width: 1920px) {
  .widgets__item {
    max-width: calc(28% + 28px);
    flex-basis: calc(28% + 28px);
  }
}

/* .user__stats_slider .react-multi-carousel-item {
  width: 250px !important;
} */
