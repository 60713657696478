.helper__nav {
  padding: 15px 40px;
  padding-top: 20px;
}

.helper-bg {
  background-image: url(https://brine-mainnet-fe-assets.s3.ap-southeast-1.amazonaws.com/img/img/background.png);
  background-position: center;
  background-size: cover;
  height: 300px;
  padding: 0 20px;
  text-align: center;
  width: 100%;
}

.hero-inner {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  max-width: 610px;
  margin: 0 auto;
}

.hero-inner input {
  border-radius: 3px;
  box-sizing: border-box;
  height: 60px;
  padding-left: 40px;
  padding-right: 20px;
  -webkit-appearance: none;
  width: 100%;
  border-radius: 10px;
  color: #686168ee;
}

.hero-inner input::placeholder {
  font-family: 'Poppins';
}

.dark .hero-inner input {
  background-color: #242731;
  color: #fff;
}

.hero-inner h2 {
  font-size: 30px;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 10px;
  text-align: center;
  color: #fff;
}

.category_card {
  width: 300px;
  text-align: center;
  padding: 40px;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.2s;
  background-color: hsla(0, 0%, 89.4%, 0.3);
  color: rgb(104, 101, 101);
}

.dark .category_card {
  background-color: #242731;
  color: #fff;
}

.category_card:hover {
  transform: scale(1.1);
}

.category_card i {
  font-size: 40px;
  margin-bottom: 20px;
}

.category_card h3 {
  font-family: 'Poppins';
  font-weight: lighter;
  font-size: 15px;
}

.help_video_container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  margin-bottom: 30px;
}

/* modal styles */

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.backdrop .center {
  display: block;
  max-width: 80%;
  max-height: 80%;
  margin: 60px auto;
  box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
  border: 1px solid rgb(63, 62, 62);
}

.accordion {
  max-width: 442px;
  font-family: 'Poppins';
  flex: 1;
}

.accordion:nth-of-type(2) {
  margin-left: 20px;
}
.accordion-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  background-color: hsla(0, 0%, 89.4%, 0.3);
  color: rgb(104, 101, 101);
}
.dark .accordion-title {
  background-color: #242731;
  color: #fff;
}
/* .accordion-title:hover {
  background-color: rgba(184, 180, 180, 0.3);
}

.dark .accordion-title:hover {
  background-color: rgba(67, 66, 66, 0.3);
} */

.accordion-content,
.accordion-title {
  padding: 1rem;
}

.accordion-content a {
  color: #8173e0;
}

.accordion-content {
  background-color: rgba(232, 228, 228, 0.3);
}

.dark .accordion-content {
  background-color: rgba(45, 45, 45, 0.3);
}
.accordion__container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 40px;
}
.theme_changer {
  margin-left: 20px;
  margin-top: 5px;
  cursor: pointer;
}

.faq_container {
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
  justify-content: space-evenly;
  align-items: flex-start;
  padding: 30px 20px;
  font-family: 'Poppins', sans-serif;
  border-radius: 20px;
  background-color: hsla(0, 0%, 89.4%, 0.3);
  min-width: 55%;
}

.faq_container .faq_info {
  /* margin-left: 20px; */
}

.faq_container .faq_titles h3 {
  padding: 10px 5px;
  cursor: pointer;
}

.faq_container .faq_titles {
  margin-top: 10px;
  width: 220px;
}

.faq_container .faq_titles h3.active {
  color: #8173e0;
}

.faq_container .faq_info {
  max-width: 700px;
}

.faq_container .faq_info .accordion-title {
  width: 620px;
}

.dark .accordion-title.line {
  border-bottom: 1px solid #373636;
}

.dark .accordion-content.line {
  border-bottom: 1px solid #373636;
}

.accordion-title.line {
  border-bottom: 1px solid #e1e1e1;
}

.accordion-content.line {
  border-bottom: 1px solid #e1e1e1;
}

.dark .faq_container {
  background-color: #282b33;
}

.faq_container .accordion-content {
  max-width: 620px;
}

.dark .accordion-content {
  background-color: #242731;
}

.help-container {
  max-width: 1300px;
  margin: 0 auto;
  overflow: auto;
  padding: 0 40px;
}

.breadcrumb {
  display: flex;
}
.breadcrumb li {
  padding: 0 0.6rem;
  display: flex;
  position: relative;
  font-family: 'Poppins', sans-serif;
}
.breadcrumb li:first-child {
  padding-left: 0rem;
}
.breadcrumb li.active a,
.breadcrumb li.active p,
.breadcrumb li.active a:visited {
  color: #8173e0;
}
.breadcrumb li.active a:hover,
.breadcrumb li.active a:active,
.breadcrumb li.active a:visited:hover,
.breadcrumb li.active a:visited:active {
  color: #8173e0;
}
.breadcrumb li a,
.breadcrumb li p,
.breadcrumb li a:visited {
  display: block;
  padding: 1rem 0;
  text-decoration: none;
  color: #808191;
  font-size: 13px;
}

.breadcrumb li a:hover,
.breadcrumb li a:active,
.breadcrumb li a:visited:hover,
.breadcrumb li a:visited:active {
  color: #808191;
}
.breadcrumb.breadcrumb--classic li:not(:last-of-type)::before {
  content: '/';
  display: block;
  position: absolute;
  font-size: 13px;
  top: 50%;
  right: 0;
  transform: translate(50%, -50%);
  color: #808191;
}

.search-content {
  margin-top: 30px;
  margin-bottom: 30px;
}

.search-content a {
  color: #8173e0;
}

.search-content .result-content {
  margin-top: 10px;
  color: #808191;
  font-family: 'Poppins', sans-serif;
}

.search-content .h6 {
  margin: 40px 0px;
}

.search__result {
  max-width: 40%;
}

.search__result:not(:last-of-type) {
  border-bottom: 1px solid #808191;
}

.search__result_title {
  font-size: 15px;
  color: #8173e0;
}

.search__result_content {
  color: #808191;
  font-size: 13px;
  margin-top: 8px;
  font-family: 'Poppins', sans-serif;
}

.accordion-title {
  color: #000;
}

.dark .accordion-title {
  color: #fff;
}

.accordion-content {
  color: #808191;
}

.page-item {
  padding: 10px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border-width: 1px;
}

.page-link {
  color: #000;
}

.dark .page-link {
  color: #ffff;
}

.page-item.active {
  background-color: #6c5dd3;
}

.dark .page-item.active .page-link {
  color: #ffff;
}

.page-item.active .page-link {
  color: #ffff;
}

.helper_page_search_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 700px) {
  .accordion {
    width: 90%;
  }
}

@media only screen and (max-width: 1259px) {
  .help_video_container {
    grid-template-columns: repeat(2, 1fr);
  }

  .theme_changer {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: hsla(0, 0%, 89.4%, 0.3);
    padding: 5px 10px;
    border-radius: 50%;
    z-index: 10;
  }

  .dark .theme_changer {
    background-color: #242731;
  }
}
@media only screen and (max-width: 800px) {
  .help_video_container {
    grid-template-columns: repeat(1, 1fr);
  }
  .accordion__container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  .accordion:nth-of-type(2) {
    margin-left: 10px;
  }
  .search__result {
    max-width: 100%;
  }

  .helper_page_search_container {
    flex-direction: column;
    align-items: flex-start;
  }

  .helper_search {
    width: 100%;
  }
}
