.login__row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-height: 100vh;
  overflow: hidden;
}

.login__col {
  padding: 80px;
}

.login__col:first-child {
  position: relative;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 44%;
  flex: 0 0 44%;
  width: 44%;
  /* background: #6c5dd3; */
  background-color: hsla(0, 0%, 89.4%, 0.3);
  color: #0000;
}

.dark .login__col:first-child {
  background: #191b20;
  color: #ffffff;
}

.login__col:nth-child(2) {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 56%;
  flex: 0 0 56%;
  width: 56%;
  -ms-flex-item-align: center;
  align-self: center;
}

.login__logo {
  display: inline-block;
  margin-bottom: 25px;
}

.login__col:nth-child(2) .login__logo {
  display: none;
}

.login__title {
  margin-bottom: 16px;
  font-size: 39px;
  font-weight: 400;
  color: #000;
}

.dark .login__title {
  color: #fff;
}

.static__banner_item {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  margin-bottom: 30px;
}
.static__banner_item .h5 {
  color: #000;
}
.static__banner_item .p {
  color: #808191;
}

.dark .static__banner_item .svg {
  background-color: #07070c;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.static__banner_item .svg {
  background-color: #e8e8ea;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  width: 48px;
  height: 48px;
  border-radius: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.static__banner_item .svg svg,
.static__banner_item .svg svg path {
  fill: #000;
}

.dark .static__banner_item .svg svg,
.dark .static__banner_item .svg svg path {
  fill: #fff;
}

.dark .static__banner_item .h5 {
  color: #fff;
}

.static__banner_item .h5 {
  font-size: 16px;
}

.static__banner_item .svg {
  margin-right: 20px;
}

.static__banner_container {
  margin-top: 80px;
}

.static__banner_item .p {
  font-size: 13px;
  font-family: 'Poppins', sans-serif;
}

.login__preview {
  /* position: absolute;
  top: 270px;
  left: -370px;
  width: 900px;
  pointer-events: none; */

  position: absolute;
  top: 278px;
  right: -100px;
  width: 400px;
  pointer-events: none;
}

.login__preview.large {
  width: 1309px;
  left: -370px;
}

.login__preview img {
  width: 100%;
}

.login__form {
  position: relative;
  z-index: 3;
  max-width: 412px;
  margin: auto;
}

.login__stage {
  margin-bottom: 20px;
}

.login__field:not(:last-child) {
  margin-bottom: 16px;
}

.login__links:not(:last-child) {
  margin-bottom: 30px;
}

.login__link {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 13px;
  font-weight: 700;
  color: #8173e0;
  -webkit-transition: color 0.2s;
  -o-transition: color 0.2s;
  transition: color 0.2s;
}

.login__link .icon {
  margin-right: 15px;
  width: 15px;
  -webkit-transition: fill 0.2s;
  -o-transition: fill 0.2s;
  transition: fill 0.2s;
}

.login__link:hover {
  color: #11142d;
}

.login__verify {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 48px;
  margin-bottom: 40px;
  border-radius: 12px;
  border: 2px solid #e4e4e4;
  font-weight: 600;
  color: #11142d;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.login__verify .icon {
  margin-left: 9px;
  width: 15px;
  -webkit-transition: fill 0.2s;
  -o-transition: fill 0.2s;
  transition: fill 0.2s;
}

.login__verify:hover {
  background: #e4e4e4;
}

.login__verify.success {
  border-color: #4fbf67;
  background: rgba(79, 191, 103, 0.1);
  color: #4fbf67;
}

.login__verify.success .icon {
  fill: #4fbf67;
}

.login__status {
  position: absolute;
  top: 50%;
  left: 8px;
  width: 32px;
  height: 32px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  border-radius: 50%;
  border: 1px solid #6c5dd3;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.login__status:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 12px;
  height: 12px;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: #6c5dd3;
  border-radius: 50%;
}

.login__verify.success .login__status {
  border-color: #4fbf67;
  background: #4fbf67
    url("data:image/svg+xml,%3Csvg width='16' height='14' viewBox='0 0 16 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.6 8.60001L2.4 5.40001L0 7.80001L5.6 13.4L16 3.00001L13.6 0.600006L5.6 8.60001Z' fill='%23ffffff'/%3E%3C/svg%3E%0A")
    no-repeat 50% 50%/16px auto;
}

.login__verify.success .login__status:before {
  display: none;
}

.login__flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 16px;
}

.login__text {
  font-size: 13px;
  font-weight: 700;
  font-family: 'Poppins';
}

.login__flex .login__text {
  margin-right: 12px;
}

.login__note {
  margin-bottom: 32px;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.33333;
  color: #808191;
}

.login__note a {
  color: #8173e0;
}

.login__note a:hover {
  text-decoration: underline;
}

.dark .login__link .icon {
  fill: #ffffff;
}

.dark .login__link:hover {
  color: #8173e0;
}

.dark .login__link:hover .icon {
  fill: #6c5dd3;
}

.dark .login__verify {
  border-color: rgba(228, 228, 228, 0.1);
  color: #ffffff;
}

.dark .login__verify .icon {
  fill: #ffffff;
}

.dark .login__verify:hover {
  border-color: transparent;
  background: rgba(228, 228, 228, 0.1);
}

.dark .login__verify.success {
  border-color: #4fbf67;
  color: #4fbf67;
}

.dark .login__verify.success .icon {
  fill: #4fbf67;
}

.icon.icon-label {
  width: 12px;
  margin-top: -10px;
}

.react-multiple-carousel__arrow {
  z-index: 0 !important;
}

.forgot_password__container {
  padding: 10px;
  display: 'flex';
  flex-direction: 'column';
  justify-content: 'center';
}

.forgot_password__heading {
  text-align: center;
  margin-bottom: 20px;
}

.forgot_password__innercontainer {
  padding: 0px 50px 0px 50px;
}

.google__btn span {
  font-family: 'Poppins', sans-serif !important;
}

.profile__scratch {
  margin: 30px 10px 10px 10px;
  text-align: center;
}

.profile__scratch-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 20px;
}

.profile__scratch-container img {
  width: 200px;
  margin-right: 12px;
  cursor: pointer;
}

.profile__scratch-popup.mw-700 .popup_settings {
  max-width: 759px;
}

.profile__scratch-popup.mw-500 .popup_settings {
  max-width: 500px;
}

.profile__scratch-container div:hover {
  animation: shake-animation 4.72s ease infinite;
  transform-origin: 50% 50%;
  cursor: pointer;
}

.profile__scratch-container .shake {
  animation: shake-animation 4.72s ease infinite;
  transform-origin: 50% 50%;
}

.inital-scratch-card-popup {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 40px;
}

.inital-scratch-card-popup img {
  width: 320px;
}

.inital-scratch-card-popup .h5 {
  margin-bottom: 10px;
}

.inital-scratch-card-popup .h6 span {
  color: #8173e0;
}

@keyframes shake-animation {
  0% {
    transform: translate(0, 0);
  }
  1.78571% {
    transform: translate(5px, 0);
  }
  3.57143% {
    transform: translate(0, 0);
  }
  5.35714% {
    transform: translate(5px, 0);
  }
  7.14286% {
    transform: translate(0, 0);
  }
  8.92857% {
    transform: translate(5px, 0);
  }
  10.71429% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}

.meta_login {
  display: grid;
  place-items: center;
}

.meta_login img {
  margin: 10px 0px;
  max-width: 150px;
}

.meta_login .h6 {
  font-family: 'Poppins';
  font-size: 14px;
  color: #808191;
  margin: 16px 0px;
  text-align: center;
  max-width: 380px;
}

.meta_instruction__bg {
  position: absolute;
  background-color: #6c5dd3;
  height: 100px;
  width: 100%;
  left: 0;
  bottom: 0;
  z-index: -1;
  display: flex;
  justify-content: space-between;
  padding: 40px;
  font-family: 'Poppins';
  align-items: center;
  color: #fff;
}

.meta_instruction_flex_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.meta_instruction_item img {
  width: 150px;
}

.meta_instruction_item .btn {
  min-width: 80px;
  height: 34px;
  padding: 0 12px;
  border-radius: 7px;
  font-family: 'Inter', sans-serif;
  font-size: 13px;
  font-weight: 600;
  transition: all 0.2s;
}

.meta_instruction_item ul li {
  margin-top: 22px;
  font-family: 'Poppins';
}

.meta_instruction_item ul li span {
  color: #8173e0;
}

.custom_checkbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}

.custom_checkbox input {
  margin-right: 10px;
}

.custom_checkbox p {
  font-family: 'Poppins';
  font-size: 10px;
  max-width: 220px;
  text-align: center;
}

.custom_checkbox input[type='checkbox'] {
  position: relative;
  width: 60px;
  height: 30px;
  -webkit-appearance: none;
  -webkit-appearance: none;
  background: #c6c6c6;
  outline: none;
  cursor: pointer;
  border-radius: 20px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  transition: background 300ms linear;
}
.custom_checkbox input[type='checkbox']::before {
  position: absolute;
  content: '';
  width: 30px;
  height: 30px;
  top: 0px;
  left: 0px;
  border-radius: 20px;
  background-color: #fff;
  transform: scale(1.1);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: left 300ms linear;
}
.custom_checkbox input[type='checkbox']:checked {
  background: #6c5dd3;
}
.custom_checkbox input[type='checkbox']:checked::before {
  left: 33px;
  background-color: #fff;
}

.select_wallet__container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0px 10px;
  margin-bottom: 30px;
}

.mpc__wallet_container {
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: center;
  font-family: 'Poppins';
  font-size: 12px;
  color: #808191;
  max-width: 250px;
}

.mpc__wallet_container p {
  text-align: center;
}

.select_wallet_item {
  /* border: 1px solid #6c5dd3; */
  -webkit-box-align: center;
  align-items: center;
  border: 1px solid rgb(232, 236, 253);
  border-radius: 10px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  height: 52px;
  outline: none;
  transition: all 0.18s ease-in 0s;
  -webkit-user-drag: none;
  user-select: none;
  width: 290px;
  margin-top: 15px;
  position: relative;
}

.wallet__connect_warning_text {
  color: #808191;
  font-size: 14px;
}

.dark .wallet__connect_warning_text span {
  color: #fff;
}

.wallet__connect_warning_text span {
  color: #000;
}

.wallet__connect_warning_text a {
  color: #8173e0;
  text-decoration: underline;
  font-size: 14px;
}

.wallet__connect_v2_icon {
  color: #808191;
}

.dark .wallet__connect_v2_icon:hover {
  color: #fff;
}
.wallet__connect_v2_icon:hover {
  color: #000;
}
.dark .select_wallet_item {
  border: 1px solid #2a2d41;
  background-color: #191b20;
}

.select_wallet_item {
  border: 1px solid rgb(232, 236, 253);
}
.select_wallet_img {
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  margin: 0px 20px 0px 16px;
  width: 62px;
}

.select_wallet_item > span {
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 140%;
}

.select_wallet_item .h5 {
  font-size: 12px;
  margin-top: 10px;
  max-width: 105px;
  font-weight: bold;
}

.select_wallet__container .react-multi-carousel-list {
  padding: 10px;
}

.select_wallet_item img {
  width: 20px;
  height: 20px;
}

.dark .select_wallet_item {
  background: #242731;
  color: #fff;
}

.select_wallet_item:hover {
  -webkit-box-shadow: 0 0 0 1px #6c5dd3;
  box-shadow: 0 0 0 1px #6c5dd3;
  color: #8173e0;
}

.select_wallet_item.active {
  -webkit-box-shadow: 0 0 0 1px #6c5dd3;
  box-shadow: 0 0 0 1px #6c5dd3;
  color: #8173e0;
}

.login__back span {
  margin-left: 5px;
}

.login__back {
  font-family: 'Poppins', sans-serif;
  cursor: pointer;
  transition: transform 0.5s;
  width: fit-content;
  color: #808191;
  margin-left: 80px;
  margin-bottom: 20px;
  display: inline-block;
}

.login__back i {
  color: #808191;
}

.login__back:hover {
  transform: scale(1.08);
}

.theme-toggler {
  position: fixed;
  top: 30px;
  right: 40px;
  cursor: pointer;
  box-shadow: 0 15px 35px rgb(0 0 0 / 7%);
  padding: 10px;
  border-radius: 50%;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  transition: all 0s;
}

.theme-toggler:hover {
  transform: scale(1.1);
}

.theme-toggler svg {
  width: 30px;
}

.dark .theme-toggler {
  background: #242731;
  color: #fff;
}

.help__icon_signin {
  margin-left: 6px;
  cursor: pointer;
}

.help__icon_signin:hover {
  opacity: 0.6;
}

.login_verify {
  background-color: rgba(108, 93, 211, 0.1);
  padding: 15px 15px;
  border-radius: 10px;
  border: 2px solid #6c5dd3;
  width: 350px;
}

.login_verify.market__filter {
  background-color: transparent;
  padding: 0px;
  border-radius: 0px;
  border: none;
  width: 190px;
}

.login_verify.market__filter p {
  /* margin-right: 15px; */
  font-size: 12px;
}

.login_verify.market__filter input[type='checkbox']::before {
  width: 20px;
  height: 20px;
  border-radius: 10px;
}

.login_verify.market__filter input[type='checkbox'] {
  width: 38px;
  height: 21px;
}

.login_verify.market__filter input[type='checkbox']:checked::before {
  left: 20px;
  top: 0.2px;
}

.network_error_container {
  background-color: rgba(238, 192, 54, 0.2);
  border-radius: 10px;
  padding: 15px 15px 15px 15px;
  margin-bottom: 20px;
  font-family: 'Poppins';
  font-size: 11px;
  position: relative;
  text-align: center;
  border: 1px solid #edc953;
}

.network_error_container i {
  position: absolute;
  top: 15px;
  right: 20px;
  font-size: 15px;
  cursor: pointer;
}

.network_error_container a {
  color: #957f36;
  text-decoration: underline;
}
.dark .network_error_container a {
  color: #edc953;
  text-decoration: underline;
}

.indication__container {
  height: 45px;
  font-family: 'Poppins';
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.indication__container .indication__login {
  background-color: #ff6347;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 5px;
}
.indication__container p {
  font-size: 12px;
}

.network__error .popup_settings {
  padding-top: 85px;
  padding-bottom: 55px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.lb_popup .popup_settings {
  padding-bottom: 45px;
}

.lock__balance .popup_settings {
  padding-top: 85px;
  padding-bottom: 15px;
}

.lock__balance p {
  font-size: 13px;
}

.network__error.lock__balance .popup_settings img {
  margin-top: 20px;
  margin-bottom: 30px;
}

.network__error .popup_settings img {
  max-width: 160px;
  margin-bottom: 20px;
}

.network__error .popup_settings .h6 {
  margin-bottom: 20px;
}

.network__error .popup_settings p {
  max-width: 300px;
  text-align: center;
  font-family: 'Poppins';
  margin-bottom: 40px;
}

.network__error .popup_settings .card__btn {
  width: 80%;
  display: block;
}

.network__error .popup_settings .card__btn:nth-of-type(2) {
  margin-top: 15px;
}
.network__error .popup_settings .disconnect_btn {
  background-color: #f0effb;
  color: #000;
}

.dark .network__error .popup_settings .disconnect_btn {
  background-color: #33353a;
  color: #fff;
}

.-cbwsdk-try-extension {
  display: none !important;
}

.login-stepper {
  margin-left: 10px;
}

.login-stepper .login__stepper_text {
  font-size: 14px;
  margin-top: 5px;
  color: #808191;
}

.login-stepper .login__stepper_text.active {
  color: #000;
}

.dark .login-stepper .login__stepper_text.active {
  color: #fff;
}

.login-stepper .step-track-status {
  margin-right: 1rem;
}

.login-stepper .step-track-status-line {
  background: transparent;
  border-left: 2px dashed #808191;
}

.login-stepper .step-track-status-dot.completed,
.dark .login-stepper .step-track-status-dot.completed {
  background-color: #6c5dd3;
  color: #fff;
}

.login-stepper .step-track-status-dot.completed::after,
.dark .login-stepper .step-track-status-dot.completed::after {
  font-weight: 300;
  /* content: '\2713'; */
  font-family: 'Font Awesome 5 Free'; /* Adjust this based on your Font Awesome setup */
  content: '\f00c';
  font: var(--fa-font-solid);
  position: absolute;
  top: 31%;
  left: 32%;
  font-size: 14px;
  /* font-family: 'Poppins', sans-serif; */
  color: #fff;
}

.login-stepper.step-track {
  margin-top: 20px;
}

.dark .login-stepper .step-track-status-dot {
  background-color: #33353a;
}

.dark .login-stepper .step-track-status-dot {
  background-color: #33353a;
}

.login-stepper .step-track-status-dot {
  background-color: #e8e8ea;
}

.dark .login-stepper .step-track-status-dot::after {
  color: #fff;
}

.login-stepper .step-track-status-dot::after {
  color: #000;
}

@-moz-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/* IE */
@-ms-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/* Opera and prob css3 final iteration */
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes blinknew {
  50% {
    opacity: 0;
  }
}

.blink-image {
  -moz-animation: blink normal 2.5s infinite ease-in-out; /* Firefox */
  -webkit-animation: blink normal 2.5s infinite ease-in-out; /* Webkit */
  -ms-animation: blink normal 2.5s infinite ease-in-out; /* IE */
  animation: blink normal 2.2s infinite ease-in-out; /* Opera and prob css3 final iteration */
}

.w3a-modal {
  z-index: 10;
}

@media only screen and (max-width: 700px) {
  .theme-toggler {
    bottom: 25px;
    top: revert;
    left: 25px;
    z-index: 3;
  }
}
